import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Radio, Input, Select, Button, DatePicker, Tooltip } from 'antd';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  getLettersAction,
  changeLettersStatusAction,
  getAuthorsAction,
  changeLettersFilterAction,
  resetFiltersAction,
} from '@actions/letters';
import {
  lettersStatusSelector,
  letterListSelector,
  letterListLoadingSelector,
  letterListTotalSelector,
  filtersLettersSelector,
  authorListLoadingSelector,
  authorListSelector,
} from '@selectors/letters';
import { getStepTypeList } from 'utils/letters';
import LettersTable from './LettersTable';
import './LettersPage.scss';

const { Option } = Select;

const LettersPage = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  // selectors
  const letters = useSelector(letterListSelector);
  const authors = useSelector(authorListSelector);
  const isLettersLoading = useSelector(letterListLoadingSelector);
  const isAuthorLoading = useSelector(authorListLoadingSelector);
  const lettersStatus = useSelector(lettersStatusSelector);
  const lettersTotal = useSelector(letterListTotalSelector);
  const { id, recorded, patientName, author, stepType } = useSelector(filtersLettersSelector);

  // Filters
  const changeSelectFilter = (name) => (value) => dispatch(changeLettersFilterAction({ [name]: value }));
  const changeDateFilter = (value) => dispatch(changeLettersFilterAction({ recorded: value.format() }));
  const onPressEnter = () => dispatch(getLettersAction());
  const resetFilters = (name = '') => () => dispatch(resetFiltersAction(name));
  const changeInputFilter = ({ target: { name, value } }) => {
    if (name === 'id' && isNaN(+value)) {
      return;
    }
    dispatch(changeLettersFilterAction({ [name]: value }));
  };

  const changeLetterStatus = ({ target }) => {
    const isResetStepTypes = !getStepTypeList(target.value).some((type) => type.value === stepType);
    if (isResetStepTypes) {
      dispatch(resetFilters('stepType'));
    }
    dispatch(changeLettersStatusAction(target.value));
  };
  const changeTable = ({ current, pageSize }) => {
    setCurrentPage(current);
    const pagination = { top: pageSize, skip: current ? pageSize * (current - 1) : null };
    dispatch(getLettersAction(pagination));
  };

  useEffect(() => {
    dispatch(getAuthorsAction());
  }, []);

  useEffect(() => {
    dispatch(getLettersAction());
  }, [lettersStatus, recorded, author, stepType]);

  useEffect(() => {
    if (lettersTotal <= 10 && currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [lettersTotal]);

  const isLoading = isLettersLoading || isAuthorLoading;

  return (
    <div className="letters">
      <div className="letters__filters">
        <Radio.Group buttonStyle="solid" value={lettersStatus} onChange={changeLetterStatus} disabled={isLoading}>
          <Radio.Button value="inProgress">In Progress</Radio.Button>
          <Radio.Button value="completed">Completed</Radio.Button>
          <Radio.Button value="all">All</Radio.Button>
        </Radio.Group>
      </div>
      <div className="letter-list__filters">
        <div className="letter-list__filter">
          <div className="letter-list__filter-name">Job ID</div>
          <Input
            name="id"
            allowClear
            value={id}
            onChange={changeInputFilter}
            onPressEnter={onPressEnter}
            disabled={isLoading}
          />
        </div>
        <div className="letter-list__search">
          <div className="letter-list__filter-name">Recorded on</div>
          <DatePicker
            className="date-picker"
            allowClear={false}
            showToday={false}
            format="DD-MMM-YYYY"
            onChange={changeDateFilter}
            value={recorded ? moment(recorded) : undefined}
            disabled={isLoading}
          />
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={resetFilters('recorded')}
            disabled={!recorded}
          />
        </div>
        <div className="letter-list__filter patient">
          <div className="letter-list__filter-name">Patient name</div>
          <Input
            allowClear
            value={patientName}
            onChange={changeInputFilter}
            name="patientName"
            onPressEnter={onPressEnter}
            disabled={isLoading}
          />
        </div>
        <div className="letter-list__filter author">
          <div className="letter-list__filter-name">Author</div>
          <Select
            className="letter-list__filter-select"
            value={author}
            onChange={changeSelectFilter('author')}
            loading={isLoading}
            disabled={isLoading}
          >
            <Option key={0} value={0}>
              All authors
            </Option>
            {authors.map(({ fullName, id: authorId }) => (
              <Option key={authorId} value={fullName}>
                {fullName}
              </Option>
            ))}
          </Select>
        </div>
        <div className="letter-list__filter stepType">
          <div className="letter-list__filter-name">Status</div>
          <Select
            className="letter-list__filter-select"
            value={stepType}
            onChange={changeSelectFilter('stepType')}
            loading={isLoading}
            disabled={isLoading}
          >
            <Option value={0}>All</Option>
            {getStepTypeList(lettersStatus).map((type) => (
              <Option key={type.id} value={type.value}>
                {type.title}
              </Option>
            ))}
          </Select>
        </div>
        <Tooltip placement="top" title="Reset all filters">
          <Button
            className="reset"
            shape="circle"
            icon={<CloseOutlined />}
            loading={isLoading}
            onClick={resetFilters()}
          />
        </Tooltip>
      </div>
      <LettersTable
        changeTable={changeTable}
        currentPage={currentPage}
        letters={letters}
        lettersTotal={lettersTotal}
        isLoading={isLoading}
      />
    </div>
  );
};

export default LettersPage;
