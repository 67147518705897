import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { AuthorizationLayout } from 'components/templates';
import { sendEmailAction } from '@actions/auth';
import { isSubmitLoadingSelector } from '@selectors/auth';
import { LOGIN, EMAIL_CONFIRM } from 'constants/urls';

import './ChangePasswordPage.scss';

const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSubmitLoading = useSelector(isSubmitLoadingSelector);
  const onFinish = (values) => {
    dispatch(sendEmailAction(values)).then(() => history.push(EMAIL_CONFIRM.path));
  };

  const onCancel = () => history.push(LOGIN.path);

  return (
    <AuthorizationLayout>
      <h3>Forgot your password?</h3>
      <Form name="change-password" onFinish={onFinish} className="change-password">
        <Form.Item name="name" rules={[{ required: true, message: 'Please input your Username!' }]}>
          <Input autoFocus tabIndex="0" size="large" prefix={<UserOutlined />} placeholder="Username" />
        </Form.Item>
        <div className="change-password__message">We will send a confirmation link to your email address</div>
        <div className="change-password__btns">
          <Button tabIndex="0" size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Form.Item>
            <Button
              tabIndex="0"
              loading={isSubmitLoading}
              size="large"
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              OK
            </Button>
          </Form.Item>
        </div>
      </Form>
    </AuthorizationLayout>
  );
};

export default ChangePasswordPage;
