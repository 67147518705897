import React from 'react';
import { AuthorizationLayout } from 'components/templates';

const EmailConfirmPage = () => (
  <AuthorizationLayout>
    <h3 className="confirm-title">Please check your email to reset your password</h3>
  </AuthorizationLayout>
);

export default EmailConfirmPage;
