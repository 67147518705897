import { combineReducers } from 'redux';
import userProfileInfo from './userProfileInfo';
import notification from './notification';
import login from './auth';
import appointments from './appointments';
import patients from './patients';
import letters from './letters';
import letter from './letter';
import player from './player';

const rootReducer = combineReducers({
  login,
  userProfileInfo,
  appointments,
  patients,
  notification,
  letters,
  letter,
  player,
});

export default rootReducer;
