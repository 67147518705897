import { createSelector } from 'reselect';

const patientsSelector = (state) => state.patients.patients;
const filtersSelector = (state) => state.patients.patientFilters;
const currentPatientSelector = (state) => state.patients.currentPatient;

export const patientListSelector = createSelector(patientsSelector, (patients) => patients.patients);
export const patientLoadingSelector = createSelector(patientsSelector, (patients) => patients.isLoading);
export const patientListTotalSelector = createSelector(patientsSelector, (patients) => patients.total);

export const patientSelector = createSelector(currentPatientSelector, (patient) => patient.patient);
export const submitLoadingSelector = createSelector(currentPatientSelector, (patient) => patient.isSubmitLoading);
export const isLoadingSelector = createSelector(currentPatientSelector, (patient) => patient.isLoading);

export const patientFiltersSelector = createSelector(filtersSelector, (patientFilters) => patientFilters);
