import { CHANGE_PLAYER, RESET_PLAYER } from '@types/player';

export const changePlayerAction = (payload) => ({
  type: CHANGE_PLAYER,
  payload,
});

export const resetPlayerAction = () => ({
  type: RESET_PLAYER,
});
