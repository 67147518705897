import * as constants from '@types/letter';
import _ from 'lodash';
import moment from 'moment';

const initialState = {
  letter: {},
  audio: {
    audioId: null,
    audioRecordedDate: '',
  },
  document: {},
  isLetterLoading: false,
  isDocumentLoading: false,
  isLockLoading: false,
  isDeleteLoading: false,
  isCompleteLoading: false,
};

const ACTION_HANDLERS = {
  [constants.GET_LETTER_START]: (state) => ({
    ...state,
    isLetterLoading: true,
  }),
  [constants.GET_LETTER_SUCCESS]: (state, { payload: { letterMetadata } }) => {
    const { audio } = letterMetadata;
    const audioRecord = _.get(audio, 'audioSegment.audioRecord');
    return {
      ...state,
      letter: letterMetadata,
      audio: {
        audioId: audioRecord && audioRecord.id,
        audioRecordedDate: audioRecord && moment(audioRecord.created).format('DD-MMM-YYYY HH:mm'),
      },
      isLetterLoading: false,
    };
  },
  [constants.GET_LETTER_ERROR]: (state) => ({
    ...state,
    isLetterLoading: false,
  }),
  [constants.GET_DOCUMENT_START]: (state) => ({
    ...state,
    isDocumentLoading: true,
  }),
  [constants.GET_DOCUMENT_SUCCESS]: (state, { payload }) => ({
    ...state,
    isDocumentLoading: false,
    document: {
      ...payload,
      isEditorTextShown: !payload.data,
    },
  }),
  [constants.GET_DOCUMENT_ERROR]: (state) => ({
    ...state,
    isDocumentLoading: false,
  }),
  [constants.RESET_LETTER]: () => initialState,

  [constants.MANUAL_LOCK_LETTER_START]: (state) => ({
    ...state,
    isLockLoading: true,
  }),
  [constants.MANUAL_LOCK_LETTER_SUCCESS]: (state, { payload: { heldUserName, heldUserId } }) => ({
    ...state,
    letter: {
      ...state.letter,
      heldUserName,
      heldUserId,
    },
    isLockLoading: false,
  }),
  [constants.MANUAL_LOCK_LETTER_ERROR]: (state) => ({
    ...state,
    isLockLoading: false,
  }),

  [constants.DELETE_LETTER_START]: (state) => ({
    ...state,
    isDeleteLoading: true,
  }),
  [constants.DELETE_LETTER_SUCCESS]: (state) => ({
    ...state,
    isDeleteLoading: false,
  }),
  [constants.DELETE_LETTER_ERROR]: (state) => ({
    ...state,
    isDeleteLoading: false,
  }),
  [constants.COMPLETE_LETTER_START]: (state) => ({
    ...state,
    isCompleteLoading: true,
  }),
  [constants.COMPLETE_LETTER_SUCCESS]: (state) => ({
    ...state,
    isCompleteLoading: false,
  }),
  [constants.COMPLETE_LETTER_ERROR]: (state) => ({
    ...state,
    isCompleteLoading: false,
  }),
};

export default (state = initialState, action) => {
  if (action) {
    return ACTION_HANDLERS[action.type] ? ACTION_HANDLERS[action.type](state, action) : state;
  }
  return state;
};
