import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import {
  ArrowLeftOutlined,
  CopyOutlined,
  CheckOutlined,
  PrinterOutlined,
  DeleteOutlined,
  UnlockOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { Player, Editor } from 'components/organisms';
import showNotificationAction from '@actions/notification';
import { userIdSelector } from '@selectors/userProfileInfo';
import {
  getLetterAction,
  getAudioAction,
  resetLetterAction,
  autoUnlockAction,
  deleteLetterAction,
  lockElongateAction,
  completeLetterAction,
  manualLockLetterAction,
  manualUnlockLetterAction,
  checkMp3,
} from '@actions/letter';
import { Loader } from 'components/atoms';
import {
  audioRecordSelector,
  letterLoadingSelector,
  lockLoadingSelector,
  letterSelector,
  documentSelector,
  deleteLoadingSelector,
  completeLoadingSelector,
  documentLoadingSelector,
} from '@selectors/letter';
import { LOCK_ELONGATE_TIMEOUT, workflowTypes } from 'constants/letters';
import { detectIE, isMobile, detectSafari } from 'utils';
import { LETTERS } from 'constants/urls';
import Header from './Header';

import './LetterPage.scss';

const LetterPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: letterId } = useParams();
  const editor = useRef(null);

  const [isEditorReadyToShow, setIsEditorReadyToShow] = useState(false);

  const { authorName, isDeleted, heldUserName, letterStepType } = useSelector(letterSelector);
  const { audioId = null, audioRecordedDate = '' } = useSelector(audioRecordSelector);
  const { data: documentText = '', isEditorTextShown } = useSelector(documentSelector);
  const isDocumentLoading = useSelector(documentLoadingSelector);
  const isLetterLoading = useSelector(letterLoadingSelector);
  const isLockLoading = useSelector(lockLoadingSelector);
  const isDeleteLoading = useSelector(deleteLoadingSelector);
  const isCompleteLoading = useSelector(completeLoadingSelector);

  const goBack = () => history.goBack();
  const handleCopyButton = (event) => editor.current.copy(event);
  const handlePrintButton = () => editor.current.print();
  const handleDeleteButton = async () => {
    try {
      await dispatch(deleteLetterAction(letterId));
      dispatch(
        showNotificationAction({
          type: 'success',
          message: 'Success',
          description: `Letter #${letterId} deleted`,
        }),
      );
      history.push(LETTERS.path);
    } catch (error) {
      dispatch(
        showNotificationAction({
          type: 'error',
          message: 'Error',
          description: 'Something went wrong please try again later',
        }),
      );
    }
  };
  const handleCompleteButton = async () => {
    try {
      await dispatch(completeLetterAction());
      dispatch(
        showNotificationAction({
          type: 'success',
          message: 'Success',
          description: `Letter #${letterId} completed`,
        }),
      );
      history.push(LETTERS.path);
    } catch (error) {
      dispatch(
        showNotificationAction({
          type: 'error',
          message: 'Error',
          description: 'Something went wrong please try again later',
        }),
      );
    }
  };

  const handleLockButton = () => {
    if (heldUserName) {
      dispatch(manualUnlockLetterAction(letterId));
    } else {
      dispatch(manualLockLetterAction(letterId));
    }
  };

  useEffect(() => {
    dispatch(getLetterAction(letterId, history));
    return () => dispatch(resetLetterAction());
  }, [letterId]);

  useEffect(() => {
    if (audioId) {
      if (detectIE() || isMobile.iOS() || detectSafari()) {
        dispatch(checkMp3(letterId));
      } else {
        dispatch(getAudioAction(audioId));
      }
    }
  }, [audioId]);

  useEffect(() => {
    const timerLockElongate = setInterval(() => {
      dispatch(lockElongateAction(letterId));
    }, LOCK_ELONGATE_TIMEOUT);
    return () => {
      clearInterval(timerLockElongate);
      dispatch(autoUnlockAction(letterId));
    };
  }, []);

  if (!letterId) {
    return <div>Sorry, but the letter was not found</div>;
  }
  if (isLetterLoading) {
    return <Loader />;
  }

  const isLoadingButton = isLockLoading || isDeleteLoading || isCompleteLoading || isDocumentLoading;
  const isAvailableButtons = !isDeleted && workflowTypes[letterStepType] !== 'Completed';
  return (
    <div className="letter">
      <Header />
      <div className="letter__main-header">
        <Player authorName={authorName} />
        <div className="recorded">
          <div className="recorded-title">Recorded</div>
          <div className="recorded-date">{audioRecordedDate || '--'}</div>
        </div>
      </div>
      <div className="letter__editor">
        {documentText ? (
          <Editor ref={editor} isEditorReadyToShow={isEditorReadyToShow} changeEditorShow={setIsEditorReadyToShow} />
        ) : (
          isEditorTextShown && <div className="letter__editor-text">This document does not exist</div>
        )}
      </div>
      <div className="letter__actions">
        <Button onClick={goBack} icon={<ArrowLeftOutlined />}>
          Back
        </Button>
        <div className="letter__actions__block">
          <Button
            disabled={isDocumentLoading || !documentText}
            className="green-btn"
            onClick={handleCopyButton}
            icon={<CopyOutlined />}
            loading={!isEditorReadyToShow}
          >
            Copy text
          </Button>
          {isAvailableButtons && (
            <Button onClick={handleCompleteButton} icon={<CheckOutlined />} loading={isLoadingButton}>
              Complete
            </Button>
          )}
          <Button onClick={handlePrintButton} icon={<PrinterOutlined />} loading={!isEditorReadyToShow}>
            Print
          </Button>
          {isAvailableButtons && (
            <Popconfirm
              title="Are you sure delete this letter?"
              onConfirm={handleDeleteButton}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" icon={<DeleteOutlined />} loading={isLoadingButton}>
                Delete
              </Button>
            </Popconfirm>
          )}
        </div>
        {
          isAvailableButtons ? (
            <Button
              onClick={handleLockButton}
              icon={heldUserName ? <UnlockOutlined /> : <LockOutlined />}
              loading={isLoadingButton}
            >
              {heldUserName ? 'Unlock' : 'Lock'}
            </Button>
          ) : (
            <div />
          ) // for flex centering
        }
      </div>
    </div>
  );
};

const LetterPageContainer = () => (useSelector(userIdSelector) ? <LetterPage /> : <Loader />);

export default LetterPageContainer;
