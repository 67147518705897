export const APPOINTMENTS = {
  path: '/appointments',
  title: 'Video Appointments',
};

export const PATIENTS = {
  path: '/patients',
  title: 'Patients',
};

export const LETTERS = {
  path: '/letters',
  title: 'Letters',
};

export const LETTER = {
  path: '/letters/:id',
  title: 'Letter',
};

export const LOGIN = {
  path: '/login',
  title: 'Login',
};

export const CHANGE_PASSWORD = {
  path: '/change-password',
  title: 'Change password',
};

export const EMAIL_CONFIRM = {
  path: '/change-password/confirm',
  title: 'Confirm change password',
};

export const RESET_PASSWORD_CONFIRM = {
  path: '/reset-password/confirm',
  title: 'Confirm reset password',
};

export const RESET_PASSWORD = {
  path: '/Account/ResetPassword',
  title: 'Reset password',
};

export const HELP_LINK =
  'https://docs.google.com/document/d/1EnWJX5poBYUil4Hheg_jmRfIIozWtaNKtlEO0zcZemI/edit?usp=sharing';
