import qs from 'qs';
import { API_URL } from 'constants/api';
import { getToken } from 'utils/auth';
import { logoutAction } from '@actions/auth';
// eslint-disable-next-line import/no-cycle
import store from '../redux';

export const ApiMethod = {
  POST: 'POST',
  PUT: 'PUT',
  GET: 'GET',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

const ResolveMethod = {
  JSON: 'json',
  BLOB: 'blob',
  TEXT: 'text',
};

export const QS_OPTIONS = { addQueryPrefix: true, arrayFormat: 'repeat' };

const selectResolveMethod = (resolveMethod = ResolveMethod.JSON) => {
  switch (resolveMethod) {
    case ResolveMethod.TEXT:
      return (response) => response.text();
    case ResolveMethod.BLOB:
      return (response) => response.blob();
    default:
      return (response) => response.json();
  }
};

const handleResponse = (response, resolveMethod) => {
  const resolveResponse = selectResolveMethod(resolveMethod);
  return new Promise((resolve) => {
    resolveResponse(response)
      .then((result) => resolve(result))
      .catch(() => resolve());
  });
};

export const getBodyAndHeaders = (body, headers) => {
  const isFormData = body instanceof FormData;
  const isString = typeof body === 'string';
  const token = getToken();
  const defaultHeaders = {};
  if (!isFormData) {
    defaultHeaders['Content-Type'] = 'application/json';
  }
  if (token) {
    defaultHeaders.Authorization = `Bearer ${token}`;
  }
  return {
    headers: new Headers({ ...defaultHeaders, ...headers }),
    body: isFormData || isString ? body : JSON.stringify(body),
  };
};

export default ({ body, headers = {}, url, params, method = ApiMethod.GET, resolveMethod }) => {
  /* eslint-disable prefer-promise-reject-errors */
  return new Promise((resolve, reject) => {
    fetch(API_URL + url + (params ? qs.stringify(params, QS_OPTIONS) : ''), {
      method,
      ...getBodyAndHeaders(body, headers),
    })
      .then((response) => {
        if (response.ok) {
          handleResponse(response, resolveMethod).then((result) => resolve(result));
        } else {
          handleResponse(response).then((result) => {
            reject({ ...response, status: response.status, statusText: response.statusText, ...result });
          });
          switch (response.status) {
            case 401:
              store.dispatch(logoutAction());
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  /* eslint-enable prefer-promise-reject-errors */
};
