import { notification } from 'antd';

const notify = ({ message, description, type, duration }) => {
  notification[type]({
    message,
    description,
    duration,
  });
};

export default notify;
