export const inProgressSteps = [
  { id: 1, title: 'Audio Capture', value: 'AudioCapture' },
  { id: 2, title: 'Processing', value: 'FastBatchRecognition' },
  { id: 3, title: 'Validation', value: 'Validation' },
  { id: 4, title: 'Review', value: 'Review' },
];

export const completedSteps = [
  { id: 5, title: 'Completed', value: 'Completion' },
  { id: 6, title: 'Deleted', value: 'Deleted' },
];

export const workflowTypes = {
  1000: 'Audio Capture',
  2002: 'Processing',
  3000: 'Validation',
  5000: 'Review',
  9000: 'Completed',
};

export const LOCK_ELONGATE_TIMEOUT = 180000; // 1 min = 60000
