import { createSelector } from 'reselect';

const currentAppointmentSelector = (state) => state.appointments.currentAppointment;
const appointmentsSelector = (state) => state.appointments.appointments;
const practicesSelector = (state) => state.appointments.practices;
const diariesSelector = (state) => state.appointments.diaries;
const cliniciansSelector = (state) => state.appointments.clinicians;
const dateSelector = (state) => state.appointments.rangeDate;

export const rangeDateSelector = createSelector(dateSelector, (rangeDate) => rangeDate);

export const appointmentSelector = createSelector(currentAppointmentSelector, (appointment) => appointment.appointment);
export const appointmentLoadingSelector = createSelector(
  currentAppointmentSelector,
  (appointment) => appointment.isLoading,
);
export const submitLoadingSelector = createSelector(
  currentAppointmentSelector,
  (appointment) => appointment.isSubmitLoading,
);
export const deleteLoadingSelector = createSelector(
  currentAppointmentSelector,
  (appointment) => appointment.isDeleteLoading,
);

export const appointmentListSelector = createSelector(
  appointmentsSelector,
  (appointments) => appointments.appointments,
);
export const appointmentsLoadingSelector = createSelector(appointmentsSelector, (appointment) => appointment.isLoading);

export const practiceListSelector = createSelector(practicesSelector, (practices) => practices.practices);
export const selectedPracticeSelector = createSelector(practicesSelector, (practices) => practices.selectedPractice);
export const practiceLoadingSelector = createSelector(practicesSelector, (practices) => practices.isLoading);

export const diaryListSelector = createSelector(diariesSelector, (diaries) => diaries.diaries);
export const selectedDiarySelector = createSelector(diariesSelector, (diaries) => diaries.selectedDiary);
export const diaryLoadingSelector = createSelector(diariesSelector, (diaries) => diaries.isLoading);

export const clinicianListSelector = createSelector(cliniciansSelector, (clinicians) => clinicians.clinicians);
export const selectedClinicianSelector = createSelector(
  cliniciansSelector,
  (clinicians) => clinicians.selectedClinician,
);
export const clinicianLoadingSelector = createSelector(cliniciansSelector, (clinicians) => clinicians.isLoading);
