import { DEFAULT_CLINICIAN_ID } from 'constants/appointments';
import moment from 'moment';

export const getSortedArray = (array, key) =>
  array.sort((firstItem, secondItem) => (firstItem[key] > secondItem[key] ? 1 : -1));

export const getClinicianData = (payload) => {
  const sortedClinicians = getSortedArray(payload, 'fullName');
  const selectedClinician = sortedClinicians.find(
    ({ id }) => id === Number(localStorage.getItem(DEFAULT_CLINICIAN_ID)),
  );
  return {
    clinicians: sortedClinicians,
    selectedClinician: selectedClinician || sortedClinicians[0] || {},
    isLoading: false,
  };
};

export const getRangeDates = (dates, range) => {
  let ge;
  let lt;
  switch (range) {
    case 'week': {
      const [start, , , , , , end] = dates;
      ge = start;
      lt = new Date(moment(end).set({ hours: 23, minutes: 59, seconds: 59, millisecond: 999 }));
      break;
    }
    case 'day': {
      const [start] = dates;
      ge = start;
      lt = new Date(moment(start).set({ hours: 23, minutes: 59, seconds: 59, millisecond: 999 }));
      break;
    }
    case 'month':
    case 'agenda': {
      const { start, end } = dates;
      ge = start;
      lt = end;
      break;
    }

    default:
      break;
  }
  return { ge, lt };
};

export const getEndDate = (startTime, duration) => {
  return new Date(moment(startTime).add(duration, 'seconds'));
};

export const set24HourFormat = ({ start, end }) => `${moment(start).format('HH:mm')}-${moment(end).format('HH:mm')}`;
