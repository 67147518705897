import * as constants from '@types/appointments';
import { DEFAULT_PRACTICE_ID, DEFAULT_DIARY_ID } from 'constants/appointments';
import { getSortedArray, getClinicianData, getEndDate } from 'utils/appointments';

const initialState = {
  appointments: {
    appointments: [],
    isLoading: false,
  },
  currentAppointment: {
    appointment: {},
    isSubmitLoading: false,
    isDeleteLoading: false,
    isLoading: false,
  },
  practices: {
    practices: [],
    isLoading: false,
    selectedPractice: {},
  },
  diaries: {
    diaries: [],
    isLoading: false,
    selectedDiary: {},
  },
  clinicians: {
    clinicians: [],
    isLoading: false,
    selectedClinician: {},
  },
  rangeDate: {
    ge: '',
    lt: '',
  },
};

const ACTION_HANDLERS = {
  [constants.GET_APPOINTMENTS_START]: (state) => ({
    ...state,
    appointments: {
      ...state.appointments,
      isLoading: true,
    },
  }),
  [constants.GET_APPOINTMENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    appointments: {
      ...state.appointments,
      appointments: payload.value.map((appointment) => ({
        ...appointment,
        title: appointment.patientName,
        start: new Date(appointment.dateAdmitted),
        end: getEndDate(appointment.dateAdmitted, appointment.duration),
      })),
      isLoading: false,
    },
  }),
  [constants.GET_APPOINTMENTS_ERROR]: (state) => ({
    ...state,
    appointments: {
      ...state.appointments,
      isLoading: false,
    },
  }),
  [constants.SET_APPOINTMENT]: (state, { payload }) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      appointment: payload,
    },
  }),
  [constants.CREATE_APPOINTMENT_START]: (state) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      isSubmitLoading: true,
    },
  }),
  [constants.CREATE_APPOINTMENT_SUCCESS]: (state) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      isSubmitLoading: false,
    },
  }),
  [constants.CREATE_APPOINTMENT_ERROR]: (state) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      isSubmitLoading: false,
    },
  }),
  [constants.UPDATE_APPOINTMENT_START]: (state) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      isSubmitLoading: true,
    },
  }),
  [constants.UPDATE_APPOINTMENT_SUCCESS]: (state) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      isSubmitLoading: false,
    },
  }),
  [constants.UPDATE_APPOINTMENT_ERROR]: (state) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      isSubmitLoading: false,
    },
  }),
  [constants.DELETE_APPOINTMENT_START]: (state) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      isDeleteLoading: true,
    },
  }),
  [constants.DELETE_APPOINTMENT_SUCCESS]: (state) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      isDeleteLoading: false,
    },
  }),
  [constants.DELETE_APPOINTMENT_ERROR]: (state) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      isDeleteLoading: false,
    },
  }),
  [constants.GET_PRACTICES_START]: (state) => ({
    ...state,
    practices: {
      ...state.practices,
      isLoading: true,
    },
  }),
  [constants.GET_PRACTICES_SUCCESS]: (state, { payload }) => {
    const sortedPractices = getSortedArray(payload, 'name');
    const selectedPractice = sortedPractices.find(({ id }) => id === Number(localStorage.getItem(DEFAULT_PRACTICE_ID)));
    return {
      ...state,
      practices: {
        ...state.practices,
        practices: sortedPractices,
        selectedPractice: selectedPractice || sortedPractices[0] || {},
        isLoading: false,
      },
    };
  },
  [constants.GET_PRACTICES_ERROR]: (state) => ({
    ...state,
    practices: {
      ...state.practices,
      isLoading: false,
    },
  }),
  [constants.SELECT_PRACTICE]: (state, { practiceId }) => ({
    ...state,
    practices: {
      ...state.practices,
      selectedPractice: state.practices.practices.find((practice) => practice.id === practiceId),
    },
  }),
  [constants.SELECT_DIARY]: (state, { diaryId }) => ({
    ...state,
    diaries: {
      ...state.diaries,
      selectedDiary: state.diaries.diaries.find((diary) => diary.id === diaryId),
    },
  }),
  [constants.GET_DIARIES_START]: (state) => ({
    ...state,
    diaries: {
      ...state.diaries,
      isLoading: true,
    },
  }),
  [constants.GET_DIARIES_SUCCESS]: (state, { payload }) => {
    const sortedDiaries = getSortedArray(
      payload.filter((item) => item.clinic),
      'name',
    );
    sortedDiaries.push({ id: 'All diaries', name: 'All diaries' });
    const selectedDiares = sortedDiaries.find(({ id }) => String(id) === localStorage.getItem(DEFAULT_DIARY_ID));
    return {
      ...state,
      diaries: {
        ...state.diaries,
        diaries: sortedDiaries,
        selectedDiary: selectedDiares || sortedDiaries[0] || {},
        isLoading: false,
      },
      clinicians: {
        ...state.clinicians,
        clinicians: sortedDiaries.length ? state.clinicians.clinicians : [],
        selectedClinician: sortedDiaries.length ? state.clinicians.selectedClinician : {},
      },
    };
  },
  [constants.GET_DIARIES_ERROR]: (state) => ({
    ...state,
    diaries: {
      ...state.diaries,
      isLoading: false,
    },
  }),
  [constants.SELECT_CLINICIAN]: (state, { clinicianId }) => ({
    ...state,
    clinicians: {
      ...state.clinicians,
      selectedClinician: state.clinicians.clinicians.find((clinician) => clinician.id === clinicianId),
    },
  }),
  [constants.GET_ALL_CLINICIAN_START]: (state) => ({
    ...state,
    clinicians: {
      ...state.clinicians,
      isLoading: true,
    },
  }),
  [constants.GET_ALL_CLINICIAN_SUCCESS]: (state, { payload }) => ({
    ...state,
    clinicians: {
      ...state.clinicians,
      ...getClinicianData(payload),
    },
  }),
  [constants.GET_ALL_CLINICIAN_ERROR]: (state) => ({
    ...state,
    clinicians: {
      ...state.clinicians,
      isLoading: false,
    },
  }),
  [constants.GET_CLINICIAN_START]: (state) => ({
    ...state,
    clinicians: {
      ...state.clinicians,
      isLoading: true,
    },
  }),
  [constants.GET_CLINICIAN_SUCCESS]: (state, { payload }) => ({
    ...state,
    clinicians: {
      ...state.clinicians,
      ...getClinicianData(payload),
    },
  }),
  [constants.GET_CLINICIAN_ERROR]: (state) => ({
    ...state,
    clinicians: {
      ...state.clinicians,
      isLoading: false,
    },
  }),
  [constants.SET_RANGE_DATE]: (state, { payload }) => ({
    ...state,
    rangeDate: payload,
  }),
  [constants.GET_APPOINTMENT_START]: (state) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      isLoading: true,
    },
  }),
  [constants.GET_APPOINTMENT_SUCCESS]: (state, { payload }) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      appointment: {
        ...state.currentAppointment.appointment,
        ...payload,
        title: payload.patientName,
        start: new Date(payload.dateAdmitted),
        end: getEndDate(payload.dateAdmitted, payload.duration),
      },
      isLoading: false,
    },
  }),
  [constants.GET_APPOINTMENT_ERROR]: (state) => ({
    ...state,
    currentAppointment: {
      ...state.currentAppointment,
      isLoading: false,
    },
  }),
  [constants.RESET_CLINICIAN]: (state) => ({
    ...state,
    clinicians: {
      ...state.clinicians,
      selectedClinician: {},
    },
  }),
  [constants.RESET_DIARY]: (state) => ({
    ...state,
    diaries: {
      ...state.diaries,
      selectedDiary: {},
    },
  }),
};

export default (state = initialState, action) => {
  if (action) {
    return ACTION_HANDLERS[action.type] ? ACTION_HANDLERS[action.type](state, action) : state;
  }
  return state;
};
