import SHOW_NOTIFICATION from '@types/notification';

const initialState = {
  data: {
    message: '',
    description: '',
  },
};

const ACTION_HANDLERS = {
  [SHOW_NOTIFICATION]: (state, { payload }) => ({
    ...state,
    data: payload,
  }),
};

export default (state = initialState, action) => {
  if (action) {
    return ACTION_HANDLERS[action.type] ? ACTION_HANDLERS[action.type](state, action) : state;
  }
  return state;
};
