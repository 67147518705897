import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { AuthorizationLayout } from 'components/templates';
import { isSubmitLoadingSelector } from '@selectors/auth';
import { resetPassword } from '@actions/auth';
import { PASSWORD_VALIDATION } from 'utils/validation';
import { LOGIN, RESET_PASSWORD_CONFIRM } from 'constants/urls';

import './ResetPasswordPage.scss';

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const [form] = Form.useForm();
  const [confirmDirty, setConfirmDirty] = useState(false);

  const isSubmitLoading = useSelector(isSubmitLoadingSelector);

  useEffect(() => {
    const { code = '', userId = '' } = queryString.parse(search);
    if (!code || !userId) {
      history.push(`/`);
    }
  }, []);

  const onFinish = ({ password, confirmPassword }) => {
    const { code = '', userId = '' } = queryString.parse(search);
    const requestData = {
      password,
      confirmPassword,
      code,
      userId,
    };

    dispatch(resetPassword(requestData)).then(() => history.push(RESET_PASSWORD_CONFIRM.path));
  };

  const onCancel = () => history.push(LOGIN.path);

  const handleConfirmBlur = ({ target: { value } }) => setConfirmDirty(confirmDirty || !!value);

  const compareToFirstPasswordRule = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }

      return Promise.reject(new Error('Two passwords that you enter are inconsistent!'));
    },
  });

  return (
    <AuthorizationLayout>
      <h3>Reset your password</h3>
      <Form name="reset-password" className="reset-password-form" form={form} onFinish={onFinish}>
        <Form.Item
          name="password"
          hasFeedback
          rules={[{ required: true, message: 'Please input new password!' }, PASSWORD_VALIDATION()]}
        >
          <Input.Password tabIndex="0" size="large" prefix={<LockOutlined />} placeholder="New password" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          hasFeedback
          dependencies={['password']}
          rules={[{ required: true, message: 'Please confirm new password!' }, compareToFirstPasswordRule]}
        >
          <Input.Password
            tabIndex="0"
            size="large"
            prefix={<LockOutlined />}
            placeholder="Confirm new password"
            onBlur={handleConfirmBlur}
          />
        </Form.Item>
        <div className="change-password__btns">
          <Button tabIndex="0" size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Form.Item>
            <Button
              tabIndex="0"
              loading={isSubmitLoading}
              size="large"
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              OK
            </Button>
          </Form.Item>
        </div>
      </Form>
    </AuthorizationLayout>
  );
};

export default ResetPasswordPage;
