import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { getPatientsAction, setPatientFilterAction, resetPatientFilterAction } from '@actions/patients';
import {
  patientListSelector,
  patientLoadingSelector,
  patientListTotalSelector,
  patientFiltersSelector,
} from '@selectors/patients';
import { selectedPracticeSelector } from '@selectors/appointments';
import defaultPatientsSearch from 'constants/patients';

import './PatientSearch.scss';

const PatientSearchContainer = ({ renderFilters, columns }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState(defaultPatientsSearch);
  const [currentPage, setCurrentPage] = useState(1);

  const filters = useSelector(patientFiltersSelector);
  const patients = useSelector(patientListSelector);
  const patientsTotal = useSelector(patientListTotalSelector);
  const isLoading = useSelector(patientLoadingSelector);
  const selectedPractice = useSelector(selectedPracticeSelector);

  const getPatients = (pagination = { top: 10 }) => {
    dispatch(getPatientsAction({ ...pagination }));
  };

  const refreshPatientsList = () => {
    dispatch(setPatientFilterAction(search));
  };

  const changeDateOfBirth = (date) => {
    setSearch({ ...search, dateOfBirth: date });
  };

  const changeSearchField = (event) => {
    setSearch({ ...search, [event.target.name]: event.target.value });
  };

  const changeTable = ({ current, pageSize }) => {
    setCurrentPage(current);
    const pagination = { top: pageSize, skip: current ? pageSize * (current - 1) : null };
    getPatients(pagination);
  };

  const onPressEnter = ({ target }) => {
    dispatch(setPatientFilterAction({ [target.name]: target.value }));
  };

  const resetDateOfBirth = () => {
    setSearch({ ...search, dateOfBirth: '' });
    dispatch(setPatientFilterAction({ dateOfBirth: '' }));
  };

  const resetSearchFilters = () => {
    resetDateOfBirth();
    dispatch(resetPatientFilterAction());
    setSearch(defaultPatientsSearch);
  };

  useEffect(() => {
    if (selectedPractice.id) {
      getPatients();
    }
    setCurrentPage(1);
  }, [filters, selectedPractice.id]);

  useEffect(() => {
    return () => resetSearchFilters();
  }, []);

  useEffect(() => {
    if (patientsTotal <= 10 && currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [patientsTotal]);

  return (
    <>
      {renderFilters(
        search,
        changeDateOfBirth,
        changeSearchField,
        resetSearchFilters,
        refreshPatientsList,
        resetDateOfBirth,
        onPressEnter,
        isLoading,
      )}
      <PatientsTable
        columns={columns}
        changeTable={changeTable}
        currentPage={currentPage}
        patients={patients}
        patientsTotal={patientsTotal}
        isLoading={isLoading}
      />
    </>
  );
};

PatientSearchContainer.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  renderFilters: PropTypes.func.isRequired,
};

const PatientsTable = memo(
  ({ columns, changeTable, currentPage, patients, patientsTotal, isLoading }) => {
    return (
      <Table
        bordered
        rowKey={({ id }) => id}
        loading={isLoading}
        columns={columns}
        dataSource={patients}
        pagination={{
          total: patientsTotal,
          current: currentPage,
          pageSize: 10,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        onChange={changeTable}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.columns === nextProps.columns &&
    prevProps.currentPage === nextProps.currentPage &&
    prevProps.patients === nextProps.patients &&
    prevProps.patientsTotal === nextProps.patientsTotal &&
    prevProps.isLoading === nextProps.isLoading,
);

PatientsTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  changeTable: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  patients: PropTypes.arrayOf(PropTypes.any).isRequired,
  patientsTotal: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PatientSearchContainer;
