import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Button, Tooltip, Spin } from 'antd';
import moment from 'moment';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  getPracticesAction,
  getDiariesAction,
  selectPracticeAction,
  getAllCliniciansAction,
  selectDiaryAction,
  selectClinicianAction,
  getCliniciansAction,
  getAppointmentsAction,
  setAppointmentAction,
  setRangeDateAction,
  resetClinicianAction,
  resetDiaryAction,
} from '@actions/appointments';
import { CalendarView, AppointmentModal } from 'components/organisms';
import {
  practiceListSelector,
  practiceLoadingSelector,
  selectedPracticeSelector,
  diaryListSelector,
  selectedDiarySelector,
  diaryLoadingSelector,
  clinicianListSelector,
  selectedClinicianSelector,
  clinicianLoadingSelector,
  appointmentsLoadingSelector,
  rangeDateSelector,
} from '@selectors/appointments';
import { DEFAULT_PRACTICE_ID, DEFAULT_DIARY_ID, DEFAULT_CLINICIAN_ID } from 'constants/appointments';
import './AppointmentsPage.scss';

const { Option } = Select;

const AppointmentsPage = () => {
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState('');
  const openModal = (status) => () => setModalStatus(status);
  const closeModal = () => {
    dispatch(setAppointmentAction({}));
    setModalStatus('');
  };

  const isAppointmentsLoading = useSelector(appointmentsLoadingSelector);
  const rangeDate = useSelector(rangeDateSelector);

  useEffect(() => {
    const defaultRangeDate = {
      ge: new Date(moment().day(0).set({ hours: 0, minutes: 0, seconds: 0, millisecond: 0 })),
      lt: new Date(moment().day(6).set({ hours: 23, minutes: 59, seconds: 59, millisecond: 999 })),
    };
    dispatch(setRangeDateAction(defaultRangeDate));
  }, []);

  // practices
  const practices = useSelector(practiceListSelector);
  const selectedPractice = useSelector(selectedPracticeSelector);
  const isPracticesLoading = useSelector(practiceLoadingSelector);

  const selectPractice = (practiceId) => {
    dispatch(selectPracticeAction(practiceId));
    localStorage.setItem(DEFAULT_PRACTICE_ID, practiceId);
    localStorage.removeItem(DEFAULT_DIARY_ID);
    localStorage.removeItem(DEFAULT_CLINICIAN_ID);
  };

  useEffect(() => {
    dispatch(getPracticesAction());
    return () => {
      dispatch(resetClinicianAction());
      dispatch(resetDiaryAction());
    };
  }, []);

  // diaries
  const diaries = useSelector(diaryListSelector);
  const selectedDiary = useSelector(selectedDiarySelector);
  const isDiariesLoading = useSelector(diaryLoadingSelector);
  const isAllDiaries = selectedDiary.name === 'All diaries';

  const selectDiary = (diaryId) => {
    dispatch(selectDiaryAction(diaryId));
    localStorage.setItem(DEFAULT_DIARY_ID, diaryId);
    localStorage.removeItem(DEFAULT_CLINICIAN_ID);
  };

  useEffect(() => {
    if (isFinite(selectedPractice.id)) {
      dispatch(resetDiaryAction());
      dispatch(getDiariesAction(selectedPractice.id));
    }
  }, [selectedPractice.id]);

  // clinicians
  const clinicians = useSelector(clinicianListSelector);
  const selectedClinician = useSelector(selectedClinicianSelector);
  const isCliniciansLoading = useSelector(clinicianLoadingSelector);

  const selectClinician = (clinicianId) => {
    dispatch(selectClinicianAction(clinicianId));
    localStorage.setItem(DEFAULT_CLINICIAN_ID, clinicianId);
  };

  useEffect(() => {
    if (selectedClinician.id) {
      dispatch(getAppointmentsAction());
    }
  }, [rangeDate, selectedClinician.id]);

  useEffect(() => {
    dispatch(resetClinicianAction());
    const getClinicians = async () => {
      if (isAllDiaries) {
        await dispatch(getAllCliniciansAction(selectedPractice.id));
      }
      if (isFinite(selectedDiary.id)) {
        await dispatch(getCliniciansAction(selectedDiary.id));
      }
    };
    getClinicians();
  }, [isAllDiaries, selectedDiary.id]);

  const isLoadingAppointments =
    isPracticesLoading || isDiariesLoading || isCliniciansLoading || !clinicians.length || isAppointmentsLoading;

  return (
    <>
      <Tooltip title={isAllDiaries && 'You need to select a diary'}>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={openModal('new')}
          className="create-appointment"
          disabled={!selectedClinician.id || isAllDiaries}
        >
          New video appointment
        </Button>
      </Tooltip>
      <div className="appointments">
        <div className="appointments__filter">
          <div className="appointments__filter-name">Practice</div>
          <Select
            className="appointments__filter-select"
            value={selectedPractice.id}
            onChange={selectPractice}
            loading={isPracticesLoading}
            disabled={isPracticesLoading}
          >
            {practices.map((practiceItem) => (
              <Option key={practiceItem.id} value={practiceItem.id}>
                {practiceItem.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="appointments__filter">
          <div className="appointments__filter-name">Diary</div>
          <Select
            className="appointments__filter-select"
            value={selectedDiary.id}
            onChange={selectDiary}
            loading={isDiariesLoading}
            disabled={isDiariesLoading || !practices.length}
          >
            {diaries.map((diaryItem) => (
              <Option key={diaryItem.id} value={diaryItem.id}>
                {diaryItem.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="appointments__filter">
          <div className="appointments__filter-name">Clinician</div>
          <Select
            className="appointments__filter-select"
            value={selectedClinician.id}
            onChange={selectClinician}
            loading={isCliniciansLoading}
            disabled={isCliniciansLoading || !diaries.length}
          >
            {clinicians.map((clinicianItem) => (
              <Option key={clinicianItem.id} value={clinicianItem.id}>
                {clinicianItem.fullName}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <Spin spinning={isLoadingAppointments} wrapperClassName="spinner">
        {clinicians.length ? <CalendarView openModal={openModal} isEdit={modalStatus === 'edit'} /> : <div />}
      </Spin>
      {Boolean(modalStatus) && (
        <AppointmentModal
          visible={Boolean(modalStatus)}
          closeModal={closeModal}
          isEdit={modalStatus === 'edit'}
          practice={selectedPractice}
          diary={selectedDiary}
          clinician={selectedClinician}
        />
      )}
    </>
  );
};

export default AppointmentsPage;
