import React from 'react';
import { Divider, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { APPOINTMENTS } from 'constants/urls';

import './NotFoundPage.scss';

const NotFoundPage = () => {
  const history = useHistory();
  const goToMainPage = () => {
    history.push(APPOINTMENTS.path);
  };

  return (
    <div className="not-found">
      <div>
        <h1>404</h1>
        <p>Not found</p>
        <Divider />
        <Button type="primary" onClick={goToMainPage}>
          Go back to main page
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
