import buildQuery from 'odata-query';
import moment from 'moment';
import {
  GET_PATIENTS,
  SET_PATIENT_FILTER,
  RESET_PATIENT_FILTER,
  CREATE_PATIENT,
  UPDATE_PATIENT,
  DELETE_PATIENT,
  GET_PATIENT,
  SET_PATIENT,
} from '@types/patients';
import { patientFiltersSelector } from '@selectors/patients';
import { selectedPracticeSelector } from '@selectors/appointments';
import showNotificationAction from '@actions/notification';
import { ApiMethod } from 'utils/api';

export const getPatientsAction = (params = {}) => (dispatch, getState) => {
  const store = getState();
  const { firstName, lastName, patientNo, dateOfBirth } = patientFiltersSelector(store);
  const selectedPractice = selectedPracticeSelector(store);
  const filter = {
    practiceId: selectedPractice.id,
  };
  if (firstName) {
    filter['tolower(name/firstName)'] = { contains: firstName.toLowerCase() };
  }
  if (lastName) {
    filter['tolower(name/lastName)'] = { contains: lastName.toLowerCase() };
  }
  if (patientNo) {
    filter.patientNo = patientNo;
  }

  if (dateOfBirth) {
    const ge = new Date(moment(dateOfBirth).set({ hours: 0, minutes: 0, seconds: 0, millisecond: 0 }));
    const lt = new Date(moment(dateOfBirth).set({ hours: 23, minutes: 59, seconds: 59, millisecond: 999 }));
    filter.dateOfBirth = { ge, lt };
  }

  const defaultParams = {
    count: true,
    filter,
    ...params,
  };

  const queryAppointments = buildQuery(defaultParams);
  return dispatch({
    type: GET_PATIENTS,
    requestData: {
      url: `/author/odata/Patients/PatientsService.Search(firstName='',lastName='',nhsNumber='')${queryAppointments}`,
      method: ApiMethod.GET,
    },
  });
};

export const setPatientFilterAction = (payload) => ({
  type: SET_PATIENT_FILTER,
  payload,
});

export const resetPatientFilterAction = () => ({
  type: RESET_PATIENT_FILTER,
});

export const createPatientAction = (body) => async (dispatch) => {
  try {
    await dispatch({
      type: CREATE_PATIENT,
      requestData: {
        url: '/author/pas/patients',
        method: ApiMethod.POST,
        body,
      },
    });
  } catch (error) {
    dispatch(
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: error.status === 400 ? error.statusText : 'Something went wrong please try again later',
      }),
    );
    return Promise.reject(error);
  }
};

export const updatePatientAction = (body, id) => async (dispatch) => {
  try {
    await dispatch({
      type: UPDATE_PATIENT,
      requestData: {
        url: `/author/pas/patients/${id}`,
        method: ApiMethod.PUT,
        body,
      },
    });
  } catch (error) {
    dispatch(
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: error.status === 400 ? error.statusText : 'Something went wrong please try again later',
      }),
    );
    return Promise.reject(error);
  }
};

export const deletePatientAction = (id) => async (dispatch) => {
  try {
    await dispatch({
      type: DELETE_PATIENT,
      requestData: {
        url: `/author/pas/patients/${id}`,
        method: ApiMethod.DELETE,
      },
    });
  } catch (error) {
    dispatch(
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong please try again later',
      }),
    );
    return Promise.reject(error);
  }
};

export const setPatientAction = (payload) => ({
  type: SET_PATIENT,
  payload,
});

export const getPatientAction = (id) => ({
  type: GET_PATIENT,
  requestData: {
    url: `/author/odata/Patients/PatientsService.Search(firstName='',lastName='',nhsNumber='')${buildQuery({
      filter: { id },
    })}`,
    method: ApiMethod.GET,
  },
});
