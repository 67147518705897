import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { appointmentListSelector, selectedDiarySelector } from '@selectors/appointments';

import { setAppointmentAction, setRangeDateAction } from '@actions/appointments';
import showNotificationAction from '@actions/notification';
import { getRangeDates, set24HourFormat } from 'utils/appointments';
import { checkWarningAppointment } from 'utils/validation';

const localizer = momentLocalizer(moment);

const CalendarView = ({ openModal, isEdit }) => {
  const dispatch = useDispatch();
  const [view, setView] = useState('week');

  const appointments = useSelector(appointmentListSelector);
  const selectedDiary = useSelector(selectedDiarySelector);

  const changeRange = (dates, range) => {
    const currentRange = range || view;
    const rangeDate = getRangeDates(dates, currentRange);
    dispatch(setRangeDateAction(rangeDate));
  };

  const onSelectSlot = ({ start, end }) => {
    if (selectedDiary.name === 'All diaries') {
      dispatch(
        showNotificationAction({ type: 'warning', message: 'Warning', description: 'You need to select a diary' }),
      );
    } else {
      const description = checkWarningAppointment(appointments, { start, end }, isEdit);

      if (description) {
        dispatch(showNotificationAction({ type: 'warning', message: 'Warning', description }));
      } else {
        dispatch(setAppointmentAction({ start, end }));
        openModal('new')();
      }
    }
  };

  const onSelectEvent = (appointment) => {
    dispatch(setAppointmentAction(appointment));
    openModal('edit')();
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={appointments}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 750 }}
        onSelectSlot={onSelectSlot}
        popup
        selectable
        onSelectEvent={onSelectEvent}
        onRangeChange={changeRange}
        views={['week', 'day', 'month', 'agenda']}
        view={view}
        onView={setView}
        length={7}
        messages={{
          previous: 'Prev',
        }}
        formats={{
          timeGutterFormat: 'HH:mm',
          eventTimeRangeFormat: set24HourFormat,
          agendaTimeRangeFormat: set24HourFormat,
          selectRangeFormat: set24HourFormat,
        }}
      />
    </div>
  );
};

CalendarView.propTypes = {
  openModal: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

export default CalendarView;
