import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { configure, GlobalHotKeys } from 'react-hotkeys';
import { Button } from 'antd';
import { BackwardOutlined, PauseOutlined, CaretRightOutlined, ForwardOutlined } from '@ant-design/icons';
import { detectIE, isMobile, detectSafari } from 'utils';
import { SKIP_SECONDS, SKIP_HOLD_INTERVAL, KEY_MAP } from 'constants/player';
import Duration from './Duration';
import './Player.scss';

const iconStyles = { fontSize: '27px', marginTop: '5px' };

configure({
  /**
   * Whether React HotKeys should simulate keypress events for the keys that do not
   * natively emit them.
   * @type {boolean}
   */
  simulateMissingKeyPressEvents: true,

  /**
   * Whether to call stopPropagation() on events after they are
   * handled (preventing the event from bubbling up any further, both within
   * React Hotkeys and any other event listeners bound in React).
   *
   * This does not affect the behaviour of React Hotkeys, but rather what
   * happens to the event once React Hotkeys is done with it (whether it's
   * allowed to propagate any further through the Render tree).
   */
  stopEventPropagationAfterHandling: true,

  /**
   * Whether to call stopPropagation() on events after they are
   * ignored (preventing the event from bubbling up any further, both within
   * React Hotkeys and any other event listeners bound in React).
   *
   * This does not affect the behaviour of React Hotkeys, but rather what
   * happens to the event once React Hotkeys is done with it (whether it's
   * allowed to propagate any further through the Render tree).
   */
  stopEventPropagationAfterIgnoring: true,

  /**
   * Whether to allow combination submatches - e.g. if there is an action
   * bound to cmd, pressing shift+cmd will *not* trigger that action when
   * allowCombinationSubmatches is false.
   */
  allowCombinationSubmatches: true,

  /**
   * A mapping of custom key codes to key names that you can then use in your
   * key sequences
   */
  customKeyCodes: {},
});

class Player extends PureComponent {
  player = null;

  timer = null;

  componentDidUpdate(prevProps) {
    const { isBackward, isForward, changePlayerAction } = this.props;
    if (isBackward && prevProps.isBackward !== isBackward) {
      changePlayerAction({ isBackward: false });
      this.onBackwardAction(SKIP_SECONDS, false)();
    } else if (isForward && prevProps.isForward !== isForward) {
      changePlayerAction({ isForward: false });
      this.onForwardAction(SKIP_SECONDS, false)();
    }
  }

  componentWillUnmount() {
    const { resetPlayerAction } = this.props;
    this.stopAudioPlayer();
    resetPlayerAction();
  }

  playPause = () => {
    const { changePlayerAction, playing } = this.props;
    if (this.timer) {
      clearInterval(this.timer);
    }
    changePlayerAction({ playing: !playing });
    if (document.activeElement) {
      document.activeElement.blur();
    }
  };

  onBackwardAction = (skipSeconds = SKIP_SECONDS, isBlur = true) => () => {
    const { playedSeconds } = this.props;

    if (this.timer) {
      clearInterval(this.timer);
    }

    const newDuration = playedSeconds - skipSeconds;
    if (newDuration > 0) {
      this.player.seekTo(newDuration, 'seconds');
    } else {
      this.player.seekTo(0);
    }
    if (isBlur && document.activeElement) {
      document.activeElement.blur();
    }
  };

  onBackwardDown = (skipSeconds = SKIP_SECONDS) => () => {
    const { loaded } = this.props;
    if (!loaded) return null;

    this.onBackwardAction(skipSeconds)();
    this.timer = setInterval(() => {
      const { playedSeconds } = this.props;
      let newDuration = playedSeconds;
      newDuration -= skipSeconds;
      if (newDuration > 0) {
        this.player.seekTo(newDuration, 'seconds');
      } else {
        this.player.seekTo(0);
      }
    }, SKIP_HOLD_INTERVAL);
  };

  onBackwardUp = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  onForwardAction = (skipSeconds = SKIP_SECONDS, isBlur = true) => () => {
    const { duration, playedSeconds, changePlayerAction } = this.props;
    if (this.timer) {
      clearInterval(this.timer);
    }

    const newDuration = playedSeconds + skipSeconds;
    if (newDuration > duration) {
      changePlayerAction({ played: 0.99999 });
      this.stopAudioPlayer();
      this.player.seekTo(duration - 0.00001, 'seconds');
    } else {
      this.player.seekTo(newDuration, 'seconds');
    }
    if (isBlur && document.activeElement) {
      document.activeElement.blur();
    }
  };

  onForwardDown = (skipSeconds = SKIP_SECONDS) => () => {
    const { loaded } = this.props;
    if (!loaded) return null;

    this.onForwardAction(skipSeconds)();
    this.timer = setInterval(() => {
      const { duration, playedSeconds, changePlayerAction } = this.props;
      let newDuration = playedSeconds;
      newDuration += skipSeconds;
      if (newDuration > duration) {
        changePlayerAction({ played: 0.99999 });
        this.stopAudioPlayer();
        this.player.seekTo(duration - 0.00001, 'seconds');
      } else {
        this.player.seekTo(newDuration, 'seconds');
      }
    }, SKIP_HOLD_INTERVAL);
  };

  onForwardUp = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  onPlay = () => {
    const { changePlayerAction, played } = this.props;
    if (detectIE()) {
      // audio is finished
      if (played.toFixed(3) === '1.000') {
        this.player.seekTo(0);
      } else {
        this.player.seekTo(played);
      }
    }
    changePlayerAction({ playing: true });
  };

  onPause = () => this.stopAudioPlayer();

  onSeekMouseDown = () => {
    const { changePlayerAction } = this.props;
    changePlayerAction({ seeking: true });
  };

  onSeekChange = ({ target: { value } }) => {
    const { changePlayerAction } = this.props;
    changePlayerAction({
      played: parseFloat(value / 100),
    });
    document.activeElement.blur();
  };

  onSeekMouseUp = ({ target: { value } }) => {
    const { changePlayerAction } = this.props;
    changePlayerAction({ seeking: false });
    this.player.seekTo(parseFloat(value / 100));
    document.activeElement.blur();
  };

  onProgress = (state) => {
    const { changePlayerAction, seeking } = this.props;
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      changePlayerAction(state);
    }
  };

  onEnded = () => this.stopAudioPlayer();

  stopAudioPlayer = () => {
    const { changePlayerAction } = this.props;
    changePlayerAction({ playing: false });
  };

  onDuration = (duration) => {
    const { changePlayerAction } = this.props;
    changePlayerAction({ duration });
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const { record, isLoading, authorName, playing, played, duration, mp3Data } = this.props;
    const { isMp3Available, isMp3Failed } = mp3Data;
    let message = null;

    if (detectIE() || isMobile.iOS() || detectSafari()) {
      if (!isMp3Available) {
        message = 'Audio conversion in progress, please wait. Do not close this window.';
      }
      if (isMp3Failed) {
        message = 'Audio conversion failed. Please try again or contact support if this persists.';
      }
    }

    const handlers = {
      PLAY: this.playPause,
      STOP: this.playPause,
      BF_DOWN: this.onBackwardDown(),
      BF_UP: this.onBackwardUp,
      FF_DOWN: this.onForwardDown(),
      FF_UP: this.onForwardUp,
    };

    return (
      <div className="player">
        <GlobalHotKeys keyMap={KEY_MAP} handlers={handlers}>
          {message && <div className="player__overlay">{message}</div>}
          {isLoading && <div className="player__overlay">Loading audio</div>}
          {!isLoading && !record && <div className="player__overlay">Audio not found</div>}
          <ReactPlayer
            ref={this.ref}
            loop
            className="player__block"
            width="100%"
            height="100%"
            url={record}
            playing={playing}
            playbackRate={1}
            volume={1}
            muted={false}
            onPlay={this.onPlay}
            onPause={this.onPause}
            onEnded={this.onEnded}
            onError={() => {}}
            onProgress={this.onProgress}
            onDuration={this.onDuration}
          />
          <div className="player__actions">
            <Button
              title={KEY_MAP.BF_DOWN.sequence}
              disabled={!record}
              shape="circle"
              size="large"
              icon={<BackwardOutlined style={iconStyles} />}
              onClick={this.onBackwardAction()}
            />
            <Button
              title={KEY_MAP.PLAY}
              disabled={!record}
              shape="circle"
              size="large"
              icon={playing ? <PauseOutlined style={iconStyles} /> : <CaretRightOutlined style={iconStyles} />}
              onClick={this.playPause}
            />
            <Button
              title={KEY_MAP.FF_DOWN.sequence}
              disabled={!record}
              shape="circle"
              size="large"
              icon={<ForwardOutlined style={iconStyles} />}
              onClick={this.onForwardAction()}
            />
          </div>
          <div className="player__data">
            <div className="player__information">
              <div className="player__information__time style-3">
                <Duration seconds={duration * played} /> / <Duration seconds={duration} />
              </div>
              <div className="player__information__author style-3">By {authorName}</div>
            </div>
            <input
              disabled={!record}
              className="player__progress"
              type="range"
              min={0}
              max={100}
              step={1}
              value={played * 100}
              onMouseDown={this.onSeekMouseDown}
              onChange={this.onSeekChange}
              onMouseUp={this.onSeekMouseUp}
            />
          </div>
        </GlobalHotKeys>
      </div>
    );
  }
}

Player.propTypes = {
  authorName: PropTypes.string,
  mp3Data: PropTypes.objectOf(PropTypes.any),
  playing: PropTypes.bool.isRequired,
  seeking: PropTypes.bool.isRequired,
  isBackward: PropTypes.bool.isRequired,
  isForward: PropTypes.bool.isRequired,
  played: PropTypes.number.isRequired,
  loaded: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  playedSeconds: PropTypes.number.isRequired,
  record: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  resetPlayerAction: PropTypes.func.isRequired,
  changePlayerAction: PropTypes.func.isRequired,
};

export default Player;
