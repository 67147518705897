import { connect } from 'react-redux';
import loggerAction from '@actions/logger';
import ErrorBoundary from './ErrorBoundary';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  loggerAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
