import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { letterSelector } from '@selectors/letter';
import { workflowTypes } from 'constants/letters';

const headerBlocks = [
  {
    name: 'patientLastName',
    title: '',
    render: (_, { patientLastName, patientFirstName }) => {
      return patientLastName && patientLastName ? `${patientLastName.toUpperCase()}, ${patientFirstName}` : '';
    },
  },
  {
    name: 'patientDateOfBirth',
    title: 'DoB',
    render: (dateOfBirth) => moment(dateOfBirth).format('DD-MMM-YYYY'),
  },
  {
    name: 'patientNHSNumber',
    title: 'NHS',
    render: (nhsNumber) => nhsNumber && nhsNumber.replace(/^(.{3})(.{3})(.*)$/, '$1 $2 $3'),
  },
  {
    name: 'DGL',
    title: 'DGL',
  },
];

const Header = () => {
  const letter = useSelector(letterSelector);
  const stepType = letter.isDeleted ? 'Deleted' : workflowTypes[letter.letterStepType] || '';
  const status = `${stepType ? `${stepType} - ` : ''} Job ${letter.id || '-'}`;
  return letter.patientFirstName ? (
    <div className="letter__system-header">
      {headerBlocks.map(({ name, render, title }) => (
        <div key={name} className="block">
          {title && <div className="block-title">{title}</div>}
          <div className="block-info">{(render && render(letter[name], letter)) || letter[name] || '--'}</div>
        </div>
      ))}
      <div className="block">
        <div className="block-info">{status}</div>
      </div>
    </div>
  ) : (
    <div className="letter__header">{status}</div>
  );
};

export default Header;
