import React from 'react';
import PropTypes from 'prop-types';
import './Logo.scss';

const Logo = ({ width = 115, height = 48 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 188.15 115.33"
      className="logo-svg"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M121.66,20c28.57-3.85,54.2,3.63,63.22,20.43,11.87,22.11-9.53,52-47.78,66.77S58.2,116,46.32,93.9a27.71,27.71,0,0,1-2.19-5.36,23.74,23.74,0,0,0,1,2.16c11.27,21,49.86,26.66,86.17,12.65S188,61,176.69,40c-8-15-29.95-22.13-55-19.94Z"
          />
          <path
            className="cls-1"
            d="M123.4,102.43C168.85,88.35,180,57,173.22,42.56c-6-12.76-25.6-20.24-46.69-19.76,10.25,1,28.94,6.61,37.12,17.89,9.26,12.79,7.88,35.89-40.25,61.74Z"
          />
          <path
            className="cls-1"
            d="M152.83,77.68c-56.32,48.72-104,24.61-108.55,5.78-2.94-12,1.06-24.65,13.4-30.92-8,4.07-12.51,21.44-6.11,31.22C63.7,102.29,102.72,109,152.83,77.68Z"
          />
          <path
            className="cls-2"
            d="M141.2,65.18a15.94,15.94,0,0,1-15.93,12c-7.89,0-10.73-5.33-9-11.73a16.24,16.24,0,0,1,15.79-12C140.15,53.55,142.85,59,141.2,65.18Zm-17.45.21c-1.09,4,.2,6.51,3.27,6.51s5.63-2.67,6.66-6.51-.12-6.51-3.18-6.51S124.81,61.44,123.75,65.39Z"
          />
          <path
            className="cls-2"
            d="M95.05,58.61h18c-2.73,9-6.19,13-10.05,15.63-5.06,3.5-7.47,4.25-16.12,4.25-14.6,0-19.33-6.25-16.24-17.8C73.22,50.91,81.94,42.9,96.37,42.9c9.14,0,18.92,2.4,17.79,12H101.65c0-2.56-1.44-4.81-6.73-4.81-6.89,0-11.19,4.65-12.78,10.58-1.54,5.78.26,10.43,7.15,10.43,5.45,0,8.9-2.09,11-5.78H93.4l1.65-6.73Z"
          />
          <path
            className="cls-2"
            d="M72.39,15.71h18c-2.73,9-6.19,13-10,15.63-5.06,3.5-7.47,4.26-16.12,4.26-14.6,0-19.33-6.26-16.24-17.8C50.56,8,59.28,0,73.71,0,82.85,0,92.63,2.41,91.5,12H79c.05-2.57-1.44-4.81-6.73-4.81-6.89,0-11.19,4.65-12.78,10.58-1.54,5.77.26,10.42,7.15,10.42,5.46,0,8.9-2.08,11-5.77H70.75l1.64-6.74Z"
          />
          <path
            className="cls-2"
            d="M8.94,1.44H33c10.26,0,14.37,5.62,11.4,16.68C40.87,31.27,32.23,34.8,22.61,34.8H0Zm4.14,25.82h9.3c6.42,0,9.27-4.65,10.51-9.3,1.68-6.25-.6-9.14-6.53-9.14H18Z"
          />
          <path className="cls-2" d="M100.7,1.28h11.06l-6.87,25.66h20.36l-2.06,7.69H91.76Z" />
        </g>
      </g>
    </svg>
  );
};

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Logo;
