import React from 'react';
import { Layout } from 'antd';
import { Logo, UserProfileInfo, PoweredBy } from 'components/atoms';
import SideBarMenu from './SideBarMenu';

const { Sider } = Layout;

const SideBar = () => {
  return (
    <Sider className="sider" width={210}>
      <div className="logo logo--header">
        <Logo />
        <div className="logo__additional">Secretary Portal</div>
      </div>
      <UserProfileInfo />
      <SideBarMenu />
      <PoweredBy />
    </Sider>
  );
};

export default SideBar;
