import React from 'react';
import moment from 'moment';
import { Input, DatePicker, Row, Col, Checkbox, Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { ReloadOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import PatientSearchContainer from './PatientSearchContainer';

import './PatientSearch.scss';

const PatientSearch = ({ checkedPatient, changeCheckbox }) => {
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'name',
      key: 'firstName',
      width: '23%',
      render: ({ firstName }) => firstName,
    },
    {
      title: 'Last Name',
      dataIndex: 'name',
      key: 'lastName',
      width: '23%',
      render: ({ lastName }) => lastName,
    },
    {
      title: 'Date Of Birth',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      width: '23%',
      render: (dateOfBirth) => moment(dateOfBirth).format('DD/MM/YYYY'),
    },
    {
      title: 'Patient ID',
      dataIndex: 'patientNo',
      key: 'patientNo',
      width: '23%',
      render: (patientNo) => patientNo,
    },
    {
      title: 'Select',
      dataIndex: 'patientNo',
      key: 'selectPatientID',
      render: (patientNo) => (
        <Checkbox onChange={changeCheckbox} name={patientNo} checked={checkedPatient === patientNo} />
      ),
      width: '8%',
    },
  ];

  return (
    <PatientSearchContainer
      columns={columns}
      renderFilters={(
        { firstName, lastName, patientNo, dateOfBirth },
        changeDateOfBirth,
        changeSearchField,
        resetSearchFilters,
        refreshPatientsList,
        resetDateOfBirth,
        onPressEnter,
        isLoading,
      ) => (
        <>
          <Row gutter={24} className="patient-form">
            <div className="patient-separator" />
            <div className="patient-form__reset-button">
              <Tooltip placement="top" title="Refresh">
                <Button
                  className="refresh"
                  shape="circle"
                  icon={<ReloadOutlined />}
                  loading={isLoading}
                  onClick={refreshPatientsList}
                />
              </Tooltip>
              <Tooltip placement="top" title="Reset all filters">
                <Button
                  className="reset"
                  shape="circle"
                  icon={<CloseOutlined />}
                  loading={isLoading}
                  onClick={resetSearchFilters}
                />
              </Tooltip>
            </div>
            <Col span={12}>
              <div className="patient-field">First name</div>
              <Input
                allowClear
                value={firstName}
                onChange={changeSearchField}
                name="firstName"
                onPressEnter={onPressEnter}
                disabled={isLoading}
              />
            </Col>
            <Col span={12}>
              <div className="patient-field">Last name</div>
              <Input
                allowClear
                value={lastName}
                onChange={changeSearchField}
                name="lastName"
                onPressEnter={onPressEnter}
                disabled={isLoading}
              />
            </Col>
            <Col span={12}>
              <div className="patient-field">Date of birth</div>
              <DatePicker
                className="date-picker"
                allowClear={false}
                showToday={false}
                format="DD-MMM-YYYY"
                value={dateOfBirth}
                onChange={changeDateOfBirth}
                disabled={isLoading}
              />
              <Button
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={resetDateOfBirth}
                disabled={!dateOfBirth}
              />
            </Col>
            <Col span={12}>
              <div className="patient-field">Patient ID</div>
              <Input
                allowClear
                value={patientNo}
                onChange={changeSearchField}
                name="patientNo"
                onPressEnter={onPressEnter}
                disabled={isLoading}
              />
            </Col>
          </Row>
        </>
      )}
    />
  );
};

PatientSearch.propTypes = {
  checkedPatient: PropTypes.string,
  changeCheckbox: PropTypes.func.isRequired,
};

export default PatientSearch;
