export const SKIP_SECONDS = 5;
export const SKIP_HOLD_INTERVAL = 500;

export const BF_COMMAND = 'Shift+Alt+ArrowLeft';
export const FF_COMMAND = 'Shift+Alt+ArrowRight';
export const KEY_MAP = {
  PLAY: 'Shift+Alt+ArrowDown',
  STOP: 'Shift+Alt+ArrowDown',

  BF_DOWN: {
    sequence: BF_COMMAND,
    action: 'keydown',
  },
  BF_PRESS: {
    sequence: BF_COMMAND,
    action: 'keypress',
  },
  BF_UP: {
    sequence: 'ArrowLeft',
    action: 'keyup',
  },

  FF_DOWN: {
    sequence: FF_COMMAND,
    action: 'keydown',
  },
  FF_PRESS: {
    sequence: FF_COMMAND,
    action: 'keypress',
  },
  FF_UP: {
    sequence: 'ArrowRight',
    action: 'keyup',
  },
};
