import * as constants from '@types/auth';

const initialState = {
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN_START:
    case constants.SEND_EMAIL_START:
    case constants.RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
      };
    case constants.LOGIN_SUCCESS:
    case constants.LOGIN_ERROR:
    case constants.SEND_EMAIL_SUCCESS:
    case constants.SEND_EMAIL_ERROR:
    case constants.RESET_PASSWORD_SUCCESS:
    case constants.RESET_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
