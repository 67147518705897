import { inProgressSteps, completedSteps } from 'constants/letters';

export const getStepTypeList = (lettersStatus) => {
  switch (lettersStatus) {
    case 'inProgress':
      return inProgressSteps;
    case 'completed':
      return completedSteps;
    case 'all':
      return [...inProgressSteps, ...completedSteps];

    default:
      return [];
  }
};

export const isForbiddenWorkflow = (workflow) =>
  workflow === 'AudioCapture' ||
  workflow === 'Validation' ||
  workflow === 'FastBatchRecognition' ||
  workflow === 'Processing' ||
  workflow === 'Audio Capture';

export const getLetterStatus = (workflow, letter) => {
  if (letter.isDeleted) {
    return 'Deleted';
  }
  switch (workflow) {
    case 'FastBatchRecognition':
      return 'Processing';
    case 'Completion':
      return 'Completed';
    default:
      return workflow.replace(/([A-Z])/g, ' $1').trim();
  }
};
