export const LOGIN = 'LOGIN';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT = 'LOGOUT';

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_START = 'SEND_EMAIL_START';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
