import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Button, Tooltip, Input, DatePicker, Popconfirm } from 'antd';
import { PlusCircleOutlined, ReloadOutlined, CloseOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { PatientModal, PatientSearchContainer } from 'components/organisms';

import { submitLoadingSelector } from '@selectors/patients';
import { practiceListSelector, practiceLoadingSelector, selectedPracticeSelector } from '@selectors/appointments';
import { getPracticesAction, selectPracticeAction } from '@actions/appointments';
import { deletePatientAction, getPatientsAction, setPatientAction } from '@actions/patients';
import { DEFAULT_PRACTICE_ID } from 'constants/appointments';

import './PatientsPage.scss';

const { Option } = Select;

const PatientsPage = () => {
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState('');
  const [currentId, setCurrentId] = useState();
  const openModal = (status) => () => setModalStatus(status);
  const closeModal = () => {
    setModalStatus('');
    dispatch(setPatientAction({}));
  };

  const practices = useSelector(practiceListSelector);
  const selectedPractice = useSelector(selectedPracticeSelector);
  const isPracticesLoading = useSelector(practiceLoadingSelector);

  const submitLoading = useSelector(submitLoadingSelector);

  const selectPractice = (practiceId) => {
    dispatch(selectPracticeAction(practiceId));
    localStorage.setItem(DEFAULT_PRACTICE_ID, practiceId);
  };

  const getDisabledDate = (current) => current && current > moment().endOf('day');

  const deletePatient = (id) => async () => {
    setCurrentId(id);
    await dispatch(deletePatientAction(id));
    setCurrentId();
    dispatch(getPatientsAction());
  };

  useEffect(() => {
    dispatch(getPracticesAction());
  }, []);

  const editPatient = (patient) => () => {
    dispatch(setPatientAction(patient));
    openModal('edit')();
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'name',
      key: 'firstName',
      width: '23%',
      render: ({ firstName }) => firstName,
    },
    {
      title: 'Last Name',
      dataIndex: 'name',
      key: 'lastName',
      width: '23%',
      render: ({ lastName }) => lastName,
    },
    {
      title: 'Date Of Birth',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      width: '23%',
      render: (dateOfBirth) => moment(dateOfBirth).format('DD/MM/YYYY'),
    },
    {
      title: 'Patient ID',
      dataIndex: 'patientNo',
      key: 'patientNo',
      width: '22%',
      render: (patientNo) => patientNo,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (id, patient) => (
        <div className="table-field-action">
          <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={editPatient(patient)} />
          <Popconfirm
            key="delete"
            okText="Yes"
            cancelText="No"
            placement="left"
            onConfirm={deletePatient(id)}
            title="Do you want to delete this patient"
          >
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
              loading={submitLoading && id === currentId}
            />
          </Popconfirm>
        </div>
      ),
      width: '9%',
    },
  ];

  return (
    <>
      <div className="create-patients">
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={openModal('new')} className="create-appointment">
          Add new patient
        </Button>
      </div>
      <div className="patients">
        <div className="patients__filter">
          <div className="patients__filter-name">Practice</div>
          <Select
            className="patients__filter-select"
            value={selectedPractice.id}
            onChange={selectPractice}
            loading={isPracticesLoading}
            disabled={isPracticesLoading}
          >
            {practices.map((practiceItem) => (
              <Option key={practiceItem.id} value={practiceItem.id}>
                {practiceItem.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <PatientSearchContainer
        columns={columns}
        renderFilters={(
          { firstName, lastName, patientNo, dateOfBirth },
          changeDateOfBirth,
          changeSearchField,
          resetSearchFilters,
          refreshPatientsList,
          resetDateOfBirth,
          onPressEnter,
          isLoading,
        ) => (
          <>
            <div className="patients patients-filters">
              <div className="patients__search">
                <div className="patients__filter-name">First name</div>
                <Input
                  allowClear
                  value={firstName}
                  onChange={changeSearchField}
                  name="firstName"
                  onPressEnter={onPressEnter}
                  disabled={isLoading}
                />
              </div>
              <div className="patients__search">
                <div className="patients__filter-name">Last name</div>
                <Input
                  allowClear
                  value={lastName}
                  onChange={changeSearchField}
                  name="lastName"
                  onPressEnter={onPressEnter}
                  disabled={isLoading}
                />
              </div>
              <div className="patients__search">
                <div className="patients__filter-name">Date of birth</div>
                <DatePicker
                  className="date-picker"
                  allowClear={false}
                  showToday={false}
                  format="DD-MMM-YYYY"
                  value={dateOfBirth}
                  onChange={changeDateOfBirth}
                  disabledDate={getDisabledDate}
                  disabled={isLoading}
                />
                <Button
                  type="danger"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={resetDateOfBirth}
                  disabled={!dateOfBirth}
                />
              </div>
              <div className="patients__search">
                <div className="patients__filter-name">Patient ID</div>
                <Input
                  allowClear
                  value={patientNo}
                  onChange={changeSearchField}
                  name="patientNo"
                  onPressEnter={onPressEnter}
                  disabled={isLoading}
                />
              </div>
              <div className="create-patients">
                <Tooltip placement="top" title="Refresh">
                  <Button
                    className="refresh"
                    shape="circle"
                    icon={<ReloadOutlined />}
                    loading={isLoading}
                    onClick={refreshPatientsList}
                  />
                </Tooltip>
                <Tooltip placement="top" title="Reset all filters">
                  <Button
                    className="reset"
                    shape="circle"
                    icon={<CloseOutlined />}
                    loading={isLoading}
                    onClick={resetSearchFilters}
                  />
                </Tooltip>
              </div>
            </div>
          </>
        )}
      />
      <PatientModal
        visible={Boolean(modalStatus)}
        closeModal={closeModal}
        isEdit={modalStatus === 'edit'}
        practice={selectedPractice}
      />
    </>
  );
};

export default PatientsPage;
