import * as constants from '@types/letters';

const initialState = {
  lettersStatus: 'inProgress',
  lettersTotal: 0,
  letters: [],
  authors: [],
  filters: {
    id: '',
    recorded: null,
    patientName: '',
    author: 0,
    stepType: 0,
  },
  isLettersLoading: false,
  isAuthorsLoading: false,
};

const ACTION_HANDLERS = {
  [constants.CHANGE_LETTERS_STATUS]: (state, { payload }) => ({
    ...state,
    lettersStatus: payload,
  }),
  [constants.CHANGE_LETTERS_FILTER]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...payload,
    },
  }),
  [constants.RESET_LETTERS_FILTER]: (state, { payload }) => ({
    ...state,
    filters: payload
      ? {
          ...state.filters,
          [payload]: initialState.filters[payload],
        }
      : { ...initialState.filters },
  }),
  [constants.GET_LETTERS_START]: (state) => ({
    ...state,
    isLettersLoading: true,
  }),
  [constants.GET_LETTERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    letters: payload.value,
    lettersTotal: payload['@odata.count'],
    isLettersLoading: false,
  }),
  [constants.GET_LETTERS_ERROR]: (state) => ({
    ...state,
    isLettersLoading: false,
  }),
  [constants.GET_AUTHORS_START]: (state) => ({
    ...state,
    isAuthorsLoading: true,
  }),
  [constants.GET_AUTHORS_SUCCESS]: (state, { payload }) => ({
    ...state,
    authors: payload,
    isAuthorsLoading: false,
  }),
  [constants.GET_AUTHORS_ERROR]: (state) => ({
    ...state,
    isAuthorsLoading: false,
  }),
};

export default (state = initialState, action) => {
  if (action) {
    return ACTION_HANDLERS[action.type] ? ACTION_HANDLERS[action.type](state, action) : state;
  }
  return state;
};
