/* eslint-disable camelcase */
import { LOGIN, LOGOUT, SEND_EMAIL, RESET_PASSWORD } from '@types/auth';
import queryString from 'query-string';
import { ApiMethod } from 'utils/api';
import { AUTHORIZATION_TOKEN, STORAGE_OAUTH_KEY } from 'constants/api';
import { APPOINTMENTS, LOGIN as LOGIN_URL } from 'constants/urls';
import showNotificationAction from '@actions/notification';
import { getRefreshToken } from 'utils/auth';
import moment from 'moment';

export const loginAction = (body) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: LOGIN,
      requestData: {
        body: {
          ...body,
          grant_type: 'password',
        },
        url: '/Accounts/combined-login',
        method: ApiMethod.POST,
        headers: {
          Accept: 'application/json',
          Client: AUTHORIZATION_TOKEN,
        },
      },
    });

    if (result.access_token) {
      const now = new Date();
      const authState = {
        accessToken: result.access_token,
        tokenType: result.token_type,
        refreshToken: result.refresh_token,
        expiresIn: result.expires_in,
        expiresAt: now.setSeconds(now.getSeconds() + result.expires_in),
      };
      localStorage.setItem(STORAGE_OAUTH_KEY, JSON.stringify(authState));
      window.location.pathname = APPOINTMENTS.path;
    }
  } catch ({ status, error, statusText, error_description }) {
    let description;
    switch (error) {
      case 'invalid_grant':
        description = 'Incorrect username';
        break;
      case 'wrong_password':
        description = error_description || statusText;
        break;
      case 'email_not_confirmed':
        description = 'Email not confirmed';
        break;
      case 'password_expired':
        description = 'Password Expired. Please use the "Forgot your password?" link to reset your password';
        break;
      default:
        description = statusText;
        break;
    }
    dispatch(showNotificationAction({ type: 'error', message: 'Error', description }));
  }
};

export const logoutAction = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  localStorage.removeItem(STORAGE_OAUTH_KEY);
  window.location.pathname = LOGIN_URL.path;
};

export const refreshTokenAction = () => async (dispatch) => {
  try {
    const result = await dispatch({
      type: LOGIN,
      requestData: {
        body: `grant_type=refresh_token&refresh_token=${getRefreshToken()}&client_id=ycQi8XE8`,
        url: '/OAuth/Token',
        method: ApiMethod.POST,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          Authorization: `Basic ${AUTHORIZATION_TOKEN}`,
        },
      },
    });
    if (result.status && result.status === 400) {
      dispatch(logoutAction());
    } else if (result.access_token) {
      const expiresIn = result.expires_in || 299;
      const authState = {
        accessToken: result.access_token,
        tokenType: result.token_type,
        refreshToken: result.refresh_token,
        expiresIn,
        expiresAt: moment().add(expiresIn, 'seconds').valueOf(),
      };
      localStorage.setItem(STORAGE_OAUTH_KEY, JSON.stringify(authState));
    }
  } catch (error) {
    dispatch(logoutAction());
  }
};

export const sendEmailAction = (body) => async (dispatch) => {
  try {
    await dispatch({
      type: SEND_EMAIL,
      requestData: {
        body,
        url: '/accounts/forgot-password',
        method: ApiMethod.POST,
        headers: {
          Accept: 'application/json',
        },
      },
    });
  } catch ({ status, statusText }) {
    const description = status === 404 ? 'Username not registered' : statusText;
    showNotificationAction({ type: 'error', message: 'Error', description });
    return Promise.reject(statusText);
  }
};

export const resetPassword = (bodyData) => async (dispatch) => {
  try {
    await dispatch({
      type: RESET_PASSWORD,
      requestData: {
        body: queryString.stringify(bodyData),
        url: '/accounts/reset-password',
        method: ApiMethod.POST,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    });
  } catch ({ statusText }) {
    showNotificationAction({ type: 'error', message: 'Error', description: statusText });
    return Promise.reject(statusText);
  }
};
