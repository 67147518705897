import React from 'react';
import { Link } from 'react-router-dom';
import { AuthorizationLayout } from 'components/templates';
import { LOGIN } from 'constants/urls';

const ResetPasswordConfirmPage = () => (
  <AuthorizationLayout>
    <h3 className="confirm-title">Your password has been reset</h3>
    <div className="confirm-message">
      <Link to={LOGIN.path}>Please click here to Log in</Link>
    </div>
  </AuthorizationLayout>
);

export default ResetPasswordConfirmPage;
