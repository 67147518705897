import { createSelector } from 'reselect';

const letterDataSelector = (state) => state.letter;

export const letterSelector = createSelector(letterDataSelector, (letter) => letter.letter);
export const audioRecordSelector = createSelector(letterDataSelector, (letter) => letter.audio);
export const letterLoadingSelector = createSelector(letterDataSelector, (letter) => letter.isLetterLoading);
export const documentSelector = createSelector(letterDataSelector, (letter) => letter.document);
export const documentLoadingSelector = createSelector(letterDataSelector, (letter) => letter.isDocumentLoading);
export const lockLoadingSelector = createSelector(letterDataSelector, (letter) => letter.isLockLoading);
export const deleteLoadingSelector = createSelector(letterDataSelector, (letter) => letter.isDeleteLoading);
export const completeLoadingSelector = createSelector(letterDataSelector, (letter) => letter.isCompleteLoading);
