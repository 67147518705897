import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IdleTimer from 'react-idle-timer';

import { inactivityTimeoutSelector } from '@selectors/userProfileInfo';
import { logoutAction } from '@actions/auth';

const UserActivity = () => {
  const dispatch = useDispatch();

  const inactivityTimeout = useSelector(inactivityTimeoutSelector) || 600;
  const logout = () => dispatch(logoutAction());

  return <IdleTimer onIdle={logout} timeout={inactivityTimeout * 1000} />;
};

export default UserActivity;
