import LOGGER from '@types/logger';
import { ApiMethod } from 'utils/api';

const loggerAction = (info) => ({
  type: LOGGER,
  requestData: {
    url: '/logger',
    method: ApiMethod.POST,
    body: {
      source: 'Front-End',
      type: 3,
      ...info,
    },
  },
});

export default loggerAction;
