export const GET_LETTER = 'GET_LETTER';
export const GET_LETTER_START = 'GET_LETTER_START';
export const GET_LETTER_ERROR = 'GET_LETTER_ERROR';
export const GET_LETTER_SUCCESS = 'GET_LETTER_SUCCESS';

export const GET_AUDIO = 'GET_AUDIO';
export const GET_AUDIO_START = 'GET_AUDIO_START';
export const GET_AUDIO_ERROR = 'GET_AUDIO_ERROR';
export const GET_AUDIO_SUCCESS = 'GET_AUDIO_SUCCESS';

export const GET_AUDIO_MP3 = 'GET_AUDIO_MP3';
export const GET_AUDIO_MP3_START = 'GET_AUDIO_MP3_START';
export const GET_AUDIO_MP3_ERROR = 'GET_AUDIO_MP3_ERROR';
export const GET_AUDIO_MP3_SUCCESS = 'GET_AUDIO_MP3_SUCCESS';

export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_DOCUMENT_START = 'GET_DOCUMENT_START';
export const GET_DOCUMENT_ERROR = 'GET_DOCUMENT_ERROR';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';

export const MANUAL_LOCK_LETTER = 'MANUAL_LOCK_LETTER';
export const MANUAL_LOCK_LETTER_START = 'MANUAL_LOCK_LETTER_START';
export const MANUAL_LOCK_LETTER_ERROR = 'MANUAL_LOCK_LETTER_ERROR';
export const MANUAL_LOCK_LETTER_SUCCESS = 'MANUAL_LOCK_LETTER_SUCCESS';

export const DELETE_LETTER = 'DELETE_LETTER';
export const DELETE_LETTER_START = 'DELETE_LETTER_START';
export const DELETE_LETTER_ERROR = 'DELETE_LETTER_ERROR';
export const DELETE_LETTER_SUCCESS = 'DELETE_LETTER_SUCCESS';

export const COMPLETE_LETTER = 'COMPLETE_LETTER';
export const COMPLETE_LETTER_START = 'COMPLETE_LETTER_START';
export const COMPLETE_LETTER_ERROR = 'COMPLETE_LETTER_ERROR';
export const COMPLETE_LETTER_SUCCESS = 'COMPLETE_LETTER_SUCCESS';

export const UPDATE_LETTER = 'UPDATE_LETTER';
export const AUTO_UNLOCK = 'AUTO_UNLOCK';
export const LOCK_ELONGATE = 'LOCK_ELONGATE';
export const RESET_LETTER = 'RESET_LETTER';

export const CHECK_MP3 = 'CHECK_MP3';
export const CHECK_MP3_START = 'CHECK_MP3_START';
export const CHECK_MP3_ERROR = 'CHECK_MP3_ERROR';
export const CHECK_MP3_SUCCESS = 'CHECK_MP3_SUCCESS';
