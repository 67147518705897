import {
  CREATE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  GET_PRACTICES,
  GET_DIARIES,
  SELECT_PRACTICE,
  GET_ALL_CLINICIAN,
  GET_CLINICIAN,
  SELECT_DIARY,
  SELECT_CLINICIAN,
  GET_APPOINTMENTS,
  SET_APPOINTMENT,
  SET_RANGE_DATE,
  GET_APPOINTMENT,
  RESET_CLINICIAN,
  RESET_DIARY,
} from '@types/appointments';
import buildQuery from 'odata-query';
import { ApiMethod } from 'utils/api';
import showNotificationAction from '@actions/notification';
import {
  rangeDateSelector,
  selectedPracticeSelector,
  selectedDiarySelector,
  selectedClinicianSelector,
} from '@selectors/appointments';

export const createAppointmentAction = (body) => async (dispatch) => {
  try {
    await dispatch({
      type: CREATE_APPOINTMENT,
      requestData: {
        url: '/author/pas/visits',
        method: ApiMethod.POST,
        body,
      },
    });
  } catch (error) {
    dispatch(
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: error.status === 400 ? error.statusText : 'Something went wrong please try again later',
      }),
    );
    return Promise.reject(error);
  }
};

export const updateAppointmentAction = (body, id) => async (dispatch) => {
  try {
    await dispatch({
      type: UPDATE_APPOINTMENT,
      requestData: {
        url: `/author/pas/visits/${id}`,
        method: ApiMethod.PUT,
        body,
      },
    });
  } catch (error) {
    dispatch(
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: error.status === 400 ? error.statusText : 'Something went wrong please try again later',
      }),
    );
    return Promise.reject(error);
  }
};

export const deleteAppointmentAction = (id) => async (dispatch) => {
  try {
    await dispatch({
      type: DELETE_APPOINTMENT,
      requestData: {
        url: `/author/pas/visits/${id}`,
        method: ApiMethod.DELETE,
      },
    });
  } catch (error) {
    dispatch(
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: 'Something went wrong please try again later',
      }),
    );
    return Promise.reject(error);
  }
};

export const getPracticesAction = () => ({
  type: GET_PRACTICES,
  requestData: {
    url: '/author/organization-items/my-non-clinic',
  },
});

export const selectPracticeAction = (practiceId) => ({
  type: SELECT_PRACTICE,
  practiceId,
});

export const getDiariesAction = (id) => ({
  type: GET_DIARIES,
  requestData: {
    url: `/author/organization-items/hierarchy/${id}`,
  },
});

export const selectDiaryAction = (diaryId) => ({
  type: SELECT_DIARY,
  diaryId,
});

export const getAllCliniciansAction = (practiceId) => ({
  type: GET_ALL_CLINICIAN,
  requestData: {
    url: '/author/workers/by-practice',
    params: {
      practiceId,
    },
  },
});

export const getCliniciansAction = (clinicianId) => ({
  type: GET_CLINICIAN,
  requestData: {
    url: `/author/workers/organisation-doctors/${clinicianId}`,
  },
});

export const selectClinicianAction = (clinicianId) => ({
  type: SELECT_CLINICIAN,
  clinicianId,
});

export const getAppointmentsAction = (params = {}, userId = null) => (dispatch, getState) => {
  const store = getState();
  const rangeDate = rangeDateSelector(store);
  const selectedPractice = selectedPracticeSelector(store);
  const selectedDiary = selectedDiarySelector(store);
  const selectedClinician = selectedClinicianSelector(store);

  const defaultParams = {
    filter: {
      dateAdmitted: rangeDate,
      'tolower(clinic)': { contains: selectedDiary.name && selectedDiary.name.toLowerCase() },
      patientPracticeId: selectedPractice.id,
      userProfileId: selectedClinician.id,
    },
  };

  if (selectedDiary.name === 'All diaries') {
    delete defaultParams.filter['tolower(clinic)'];
  }

  const queryAppointments = buildQuery({ ...defaultParams, ...params });
  return dispatch({
    type: GET_APPOINTMENTS,
    requestData: {
      url: `/author/odata/Visits/VisitsService.GetForUserNew(userId=${userId})${queryAppointments}`,
      method: ApiMethod.GET,
    },
  });
};

export const setAppointmentAction = (payload) => ({
  type: SET_APPOINTMENT,
  payload,
});

export const setRangeDateAction = (payload) => ({
  type: SET_RANGE_DATE,
  payload,
});

export const getAppointmentAction = (visitNo) => ({
  type: GET_APPOINTMENT,
  requestData: {
    url: `/author/pas/visits/visitNo/${visitNo}`,
  },
});

export const resetClinicianAction = () => ({
  type: RESET_CLINICIAN,
});

export const resetDiaryAction = () => ({
  type: RESET_DIARY,
});
