export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const CREATE_APPOINTMENT_START = 'CREATE_APPOINTMENT_START';
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_ERROR = 'CREATE_APPOINTMENT_ERROR';

export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const UPDATE_APPOINTMENT_START = 'UPDATE_APPOINTMENT_START';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_ERROR = 'UPDATE_APPOINTMENT_ERROR';

export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const DELETE_APPOINTMENT_START = 'DELETE_APPOINTMENT_START';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_ERROR = 'DELETE_APPOINTMENT_ERROR';

export const GET_PRACTICES = 'GET_PRACTICES';
export const SELECT_PRACTICE = 'SELECT_PRACTICE';
export const GET_PRACTICES_START = 'GET_PRACTICES_START';
export const GET_PRACTICES_SUCCESS = 'GET_PRACTICES_SUCCESS';
export const GET_PRACTICES_ERROR = 'GET_PRACTICES_ERROR';

export const GET_DIARIES = 'GET_DIARIES';
export const SELECT_DIARY = 'SELECT_DIARY';
export const GET_DIARIES_START = 'GET_DIARIES_START';
export const GET_DIARIES_SUCCESS = 'GET_DIARIES_SUCCESS';
export const GET_DIARIES_ERROR = 'GET_DIARIES_ERROR';

export const GET_ALL_CLINICIAN = 'GET_ALL_CLINICIAN';
export const SELECT_CLINICIAN = 'SELECT_CLINICIAN';
export const GET_ALL_CLINICIAN_START = 'GET_ALL_CLINICIAN_START';
export const GET_ALL_CLINICIAN_SUCCESS = 'GET_ALL_CLINICIAN_SUCCESS';
export const GET_ALL_CLINICIAN_ERROR = 'GET_ALL_CLINICIAN_ERROR';

export const GET_CLINICIAN = 'GET_CLINICIAN';
export const GET_CLINICIAN_START = 'GET_CLINICIAN_START';
export const GET_CLINICIAN_SUCCESS = 'GET_CLINICIAN_SUCCESS';
export const GET_CLINICIAN_ERROR = 'GET_CLINICIAN_ERROR';

export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_APPOINTMENTS_START = 'GET_APPOINTMENTS_START';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_ERROR = 'GET_APPOINTMENTS_ERROR';

export const SET_APPOINTMENT = 'SET_APPOINTMENT';
export const RESET_CLINICIAN = 'RESET_CLINICIAN';
export const RESET_DIARY = 'RESET_DIARY';
export const SET_RANGE_DATE = 'SET_RANGE_DATE';

export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const GET_APPOINTMENT_START = 'GET_APPOINTMENT_START';
export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS';
export const GET_APPOINTMENT_ERROR = 'GET_APPOINTMENT_ERROR';
