import {
  GET_LETTER,
  GET_AUDIO,
  RESET_LETTER,
  GET_DOCUMENT,
  MANUAL_LOCK_LETTER,
  LOCK_ELONGATE,
  AUTO_UNLOCK,
  DELETE_LETTER,
  COMPLETE_LETTER,
  UPDATE_LETTER,
  CHECK_MP3,
  GET_AUDIO_MP3,
} from '@types/letter';
import { CLIENT_ID } from 'constants/api';
import { workflowTypes } from 'constants/letters';
import showNotificationAction from '@actions/notification';
import { userIdSelector } from '@selectors/userProfileInfo';
import { letterSelector } from '@selectors/letter';
import { ApiMethod } from 'utils/api';
import { isForbiddenWorkflow } from 'utils/letters';
import { LETTERS } from 'constants/urls';

export const getLetterDocumentAction = (letterId) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: GET_DOCUMENT,
      requestData: {
        url: `/author/documents/patched?documentId=null&letterId=${letterId}`,
        method: ApiMethod.GET,
      },
    });
    return result;
  } catch (error) {
    dispatch(
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: `Failed to load document - ${error.message}`,
      }),
    );
  }
};

export const getLetterAction = (id, history) => async (dispatch, getState) => {
  try {
    const result = await dispatch({
      type: GET_LETTER,
      requestData: {
        url: `/author/clinical-letters/metadata/${id}`,
        method: ApiMethod.GET,
        headers: {
          ClientID: CLIENT_ID,
        },
      },
    });

    const userId = userIdSelector(getState());
    const { isDeleted, letterStepType, heldUserName, heldUserId } = result.letterMetadata;
    let isHoldDenied = false;
    if (userId) {
      isHoldDenied = heldUserName && heldUserId !== userId;
    }
    if (isHoldDenied) {
      dispatch(
        showNotificationAction({
          type: 'error',
          message: 'Error',
          description: `This letter is locked by ${heldUserName}`,
        }),
      );
      history.push(LETTERS.path);
      return;
    }

    if (isForbiddenWorkflow(workflowTypes[letterStepType])) {
      const description = isDeleted
        ? 'This job was deleted and as such is not available to view'
        : "Jobs can only be viewed once they have a status of 'Review'";
      dispatch(
        showNotificationAction({
          type: 'warning',
          message: 'Warning',
          description,
        }),
      );
      history.push(LETTERS.path);
      return;
    }

    const isDeletedViaMobile = isDeleted && workflowTypes[letterStepType] === 'Audio Capture';
    if (!isDeletedViaMobile) {
      dispatch(getLetterDocumentAction(id));
    }
    return result.letterMetadata;
  } catch (error) {
    let errorMessage = error.statusText;
    let url = '/';
    if (error.status === 409) {
      errorMessage = 'The letter is locked';
      url = '/letters';
    } else if (error.status === 403) {
      errorMessage = 'Letter is not accessible for current user';
      url = '/letters';
    }
    dispatch(
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: errorMessage,
      }),
    );
    history.push(url);
  }
};

export const getAudioAction = (id) => ({
  type: GET_AUDIO,
  requestData: {
    url: `/author/audio-records/${id}/stream`,
    method: ApiMethod.GET,
    headers: {
      ClientID: CLIENT_ID,
      Accept: 'application/octet-stream; base64',
    },
    resolveMethod: 'text',
  },
});

export const resetLetterAction = () => ({
  type: RESET_LETTER,
});

export const manualLockLetterAction = (letterId) => async (dispatch) => {
  try {
    await dispatch({
      type: MANUAL_LOCK_LETTER,
      requestData: {
        url: `/author/clinical-letters/${letterId}/hold`,
        method: ApiMethod.POST,
      },
    });
  } catch (error) {
    dispatch(
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: 'The letter cannot be locked.',
      }),
    );
  }
};

export const manualUnlockLetterAction = (letterId) => async (dispatch) => {
  try {
    await dispatch({
      type: MANUAL_LOCK_LETTER,
      requestData: {
        url: `/author/clinical-letters/${letterId}/unhold`,
        method: ApiMethod.POST,
      },
    });
  } catch (error) {
    dispatch(
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: 'The letter cannot be unlocked.',
      }),
    );
  }
};

export const autoUnlockAction = (letterId) => ({
  type: AUTO_UNLOCK,
  requestData: {
    url: `/author/clinical-letter-locks/unlock/${letterId}`,
    method: ApiMethod.PUT,
    headers: {
      ClientID: CLIENT_ID,
    },
  },
});

export const lockElongateAction = (letterId) => ({
  type: LOCK_ELONGATE,
  requestData: {
    url: `/author/clinical-letter-locks/elongate/${letterId}`,
    method: ApiMethod.PUT,
    headers: {
      ClientID: CLIENT_ID,
    },
  },
});

export const updateLetterAction = (documentId, body) => async (dispatch) => {
  try {
    await dispatch({
      type: UPDATE_LETTER,
      requestData: {
        url: `/author/documents-patch/update?documentId=${documentId}`,
        method: ApiMethod.PUT,
        body,
      },
    });
  } catch (error) {
    dispatch(
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: 'Changes are not saved',
      }),
    );
  }
};

export const deleteLetterAction = (letterId) => ({
  type: DELETE_LETTER,
  requestData: {
    url: '/author/clinical-letters/action',
    method: ApiMethod.PUT,
    body: {
      actionReason: 'Delete letter',
      actionType: 3,
      id: +letterId,
    },
  },
});

export const completeLetterAction = () => (dispatch, getState) => {
  return dispatch({
    type: COMPLETE_LETTER,
    requestData: {
      url: '/author/clinical-letters/complete',
      method: ApiMethod.PUT,
      headers: {
        ClientID: CLIENT_ID,
      },
      body: letterSelector(getState()),
    },
  });
};

export const getAudioMp3 = (letterId) => ({
  type: GET_AUDIO_MP3,
  requestData: {
    url: `/author/audio-records/${letterId}/stream-mp3`,
    method: ApiMethod.GET,
    headers: {
      ClientID: CLIENT_ID,
      Accept: 'application/octet-stream; base64',
    },
    resolveMethod: 'text',
  },
});

export const checkMp3 = (letterId) => async (dispatch) => {
  try {
    const { isMp3Available } = await dispatch({
      type: CHECK_MP3,
      requestData: {
        url: `/author/audio-records/${letterId}/isMp3Available`,
        method: ApiMethod.GET,
      },
    });
    if (isMp3Available) {
      dispatch(getAudioMp3(letterId));
    }
  } catch (error) {
    dispatch(
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: 'Audio conversion failed. Please try again or contact support if this persists.',
      }),
    );
  }
};
