import { GET_CONFIGURATION } from '@types/userProfileInfo';

const getConfigurationAction = () => ({
  type: GET_CONFIGURATION,
  requestData: {
    url: `/application-configuration?_=${new Date().getTime()}`,
  },
});

export default getConfigurationAction;
