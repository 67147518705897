import SHOW_NOTIFICATION from '@types/notification';
import notify from 'utils/notification';

const showNotificationAction = (payload) => {
  notify(payload);
  return {
    type: SHOW_NOTIFICATION,
    payload,
  };
};
export default showNotificationAction;
