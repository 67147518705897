import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SP_RETURN_PATH } from 'constants/api';
import { LOGIN } from 'constants/urls';

const RedirectWithSavingPath = () => {
  const history = useHistory();
  useEffect(() => {
    sessionStorage.setItem(SP_RETURN_PATH, history.location.pathname);
    history.push(LOGIN.path);
  }, [history]);

  return null;
};

export default RedirectWithSavingPath;
