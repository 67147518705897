import buildQuery from 'odata-query';
import moment from 'moment';
import {
  GET_LETTERS,
  CHANGE_LETTERS_STATUS,
  GET_AUTHORS,
  CHANGE_LETTERS_FILTER,
  RESET_LETTERS_FILTER,
} from '@types/letters';
import { ApiMethod } from 'utils/api';
import { lettersStatusSelector, filtersLettersSelector } from '@selectors/letters';

export const changeLettersStatusAction = (payload) => ({
  type: CHANGE_LETTERS_STATUS,
  payload,
});

export const getAuthorsAction = () => ({
  type: GET_AUTHORS,
  requestData: {
    url: '/author/workers/just-my-authors',
    method: ApiMethod.GET,
  },
});

export const changeLettersFilterAction = (payload) => ({
  type: CHANGE_LETTERS_FILTER,
  payload,
});

export const resetFiltersAction = (payload) => ({
  type: RESET_LETTERS_FILTER,
  payload,
});

export const getLettersAction = (params = {}) => (dispatch, getState) => {
  const store = getState();
  const lettersStatus = lettersStatusSelector(store);
  const { id, recorded, patientName, author, stepType } = filtersLettersSelector(store);

  const filter = {
    id: +id || undefined,
    createDate: recorded
      ? {
          ge: new Date(moment(recorded).utc().set({ hours: 0, minutes: 0, seconds: 0, millisecond: 0 })),
          le: new Date(moment(recorded).utc().set({ hours: 23, minutes: 59, seconds: 59, millisecond: 999 })),
        }
      : undefined,
    authorName: author || undefined,
    stepType: stepType && stepType !== 'Deleted' ? stepType : undefined,
  };
  if (patientName) {
    const splitted = patientName.split(' ');
    const splittetCleared = splitted.filter(Boolean);
    const patientNames = [];
    splittetCleared.forEach((item) => {
      patientNames.push({ patientFirstName: { startswith: item } });
      patientNames.push({ patientLastName: { startswith: item } });
    });
    filter.or = patientNames;
  }

  switch (lettersStatus) {
    case 'all':
      if (!stepType) {
        filter.isDeleted = undefined;
      } else {
        filter.isDeleted = stepType === 'Deleted';
      }
      break;
    case 'completed':
      if (!stepType) {
        filter.and = {
          or: [{ stepType: 'Completion' }, { isDeleted: true }],
        };
      } else {
        filter.isDeleted = stepType === 'Deleted';
      }
      break;
    case 'inProgress':
      filter.isDeleted = false;
      break;

    default:
      break;
  }

  const defaultParams = {
    count: true,
    top: 10,
    orderBy: 'createDate desc',
    filter,
    ...params,
  };

  const query = buildQuery(defaultParams);

  return dispatch({
    type: GET_LETTERS,
    requestData: {
      url: `/author/odata/Letters/LettersService.Search${lettersStatus === 'inProgress' ? 'Incomplete' : ''}()${query}`,
      method: ApiMethod.GET,
    },
  });
};
