import React from 'react';
import { Form, Input, DatePicker, Button, Radio } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { replacerNHS, RULE_REQUIRED, EMAIL_VALIDATOR } from 'utils/validation';

import './PatientForm.scss';

const defaultLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const PatientForm = ({ layout = defaultLayout, form, onFinish, fields, isEdit }) => {
  const resetField = (field) => () => {
    form.setFieldsValue({
      [field]: undefined,
    });
  };

  const getDisabledDate = (current) => current && current > moment().endOf('day');

  const formFields = [
    {
      label: 'First name',
      name: 'firstName',
      key: 'firstName',
      rules: [RULE_REQUIRED],
      component: <Input allowClear autoComplete="off" />,
    },
    {
      label: 'Last name',
      name: 'lastName',
      key: 'lastName',
      rules: [RULE_REQUIRED],
      component: <Input allowClear autoComplete="off" />,
    },
    {
      label: 'Gender',
      name: 'sex',
      key: 'sex',
      rules: [RULE_REQUIRED],
      component: (
        <Radio.Group>
          <Radio value="M">Male</Radio>
          <Radio value="F">Female</Radio>
        </Radio.Group>
      ),
    },
    {
      label: 'Date of birth',
      name: 'dateOfBirth',
      key: 'dateOfBirth',
      rules: [
        {
          required: true,
          message: 'Please fill out this field!',
        },
      ],
      component: (
        <DatePicker
          className="date-picker"
          allowClear={false}
          showToday={false}
          format="DD-MMM-YYYY"
          disabledDate={getDisabledDate}
        />
      ),
      customElements: (
        <Button type="danger" shape="circle" icon={<DeleteOutlined />} onClick={resetField('dateOfBirth')} />
      ),
    },
    {
      label: 'NHS No',
      name: 'nhsNumberString',
      key: 'nhsNumberString',
      rules: [
        {
          pattern: /^[\d ]*$/,
          message: 'NHS No must contain only numeric characters',
        },
        { min: 12, message: 'NHS must be 10 characters.' },
      ],
      normalize: (value) => replacerNHS(value),
      component: <Input allowClear maxLength={12} autoComplete="off" />,
    },
    {
      label: 'DGL No',
      name: 'dglNumber',
      key: 'dglNumber',
      rules: [RULE_REQUIRED],
      component: <Input allowClear autoComplete="off" />,
    },
    {
      label: 'E-mail',
      name: 'email',
      key: 'email',
      rules: [RULE_REQUIRED, EMAIL_VALIDATOR()],
      component: <Input allowClear autoComplete="off" />,
    },
    {
      label: 'Phone number',
      name: 'phone',
      key: 'phone',
      rules: [RULE_REQUIRED],
      component: <Input allowClear autoComplete="off" />,
    },
    {
      label: 'Practice',
      name: 'practice',
      key: 'practice',
      component: <Input disabled autoComplete="off" />,
    },
  ];

  if (isEdit) {
    formFields.splice(3, 0, {
      label: 'Patient ID',
      name: 'patientNo',
      key: 'patientNo',
      rules: [RULE_REQUIRED],
      component: <Input allowClear disabled autoComplete="off" />,
    });
  }

  return (
    <Form {...layout} form={form} onFinish={onFinish} fields={fields} className="patient-form-modal">
      {formFields.map(({ label, name, rules, component, key, className = '', customElements, ...others }) => (
        <div className="form-item" key={key}>
          <Form.Item label={label} name={name} rules={rules} className={className} {...others}>
            {component}
          </Form.Item>
          {customElements}
        </div>
      ))}
    </Form>
  );
};

PatientForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any),
  layout: PropTypes.objectOf(PropTypes.any),
  fields: PropTypes.arrayOf(PropTypes.any),
  onFinish: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

export default PatientForm;
