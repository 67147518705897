import React from 'react';
import { Form, Input, DatePicker, TimePicker, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getRange, isToday } from 'utils/validation';

import './AppointmentForm.scss';

const { RangePicker } = TimePicker;
const defaultLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const AppointmentForm = ({ layout = defaultLayout, form, onFinish, fields, initialValues }) => {
  const resetField = (field) => () => {
    form.setFieldsValue({
      [field]: undefined,
    });
  };

  const getDisabledDate = (current) => current && current < moment().endOf('day').subtract(1, 'd');
  const getDisabledHours = () => {
    const date = form.getFieldValue('date');
    return date && isToday(date) ? getRange(0, moment().hour()) : [];
  };
  const getDisabledMinutes = (selectedHour) => {
    const date = form.getFieldValue('date');
    return date && isToday(date) && selectedHour === moment().hour() ? getRange(0, moment().minute()) : [];
  };

  const appointmentFields = [
    {
      label: 'Practice',
      name: 'practice',
      key: 'practice',
      className: 'appointment-separator',
      component: <Input disabled />,
    },
    {
      label: 'Diary',
      name: 'diary',
      key: 'diary',
      component: <Input disabled />,
    },
    {
      label: 'Clinician',
      name: 'clinician',
      key: 'clinician',
      component: <Input disabled />,
    },
    {
      label: 'Date',
      name: 'date',
      key: 'date',
      rules: [
        {
          required: true,
          message: 'Please fill out this field!',
        },
      ],
      component: (
        <DatePicker className="date-picker" format="DD-MMM-YYYY" allowClear={false} disabledDate={getDisabledDate} />
      ),
      customElements: <Button type="danger" shape="circle" icon={<DeleteOutlined />} onClick={resetField('date')} />,
    },
    {
      label: 'Time',
      name: 'time',
      key: 'time',
      rules: [
        {
          required: true,
          message: 'Please fill out this field!',
        },
      ],
      component: (
        <RangePicker
          className="date-picker"
          format="HH:mm"
          disabledHours={getDisabledHours}
          disabledMinutes={getDisabledMinutes}
        />
      ),
      customElements: <Button type="danger" shape="circle" icon={<DeleteOutlined />} onClick={resetField('time')} />,
    },
  ];

  return (
    <Form {...layout} form={form} onFinish={onFinish} fields={fields} initialValues={initialValues}>
      {appointmentFields.map(({ label, name, rules, component, key, className = '', customElements, ...others }) => (
        <div className="form-item" key={key}>
          <Form.Item label={label} name={name} rules={rules} className={className} {...others}>
            {component}
          </Form.Item>
          {customElements}
        </div>
      ))}
    </Form>
  );
};

AppointmentForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any),
  layout: PropTypes.objectOf(PropTypes.any),
  fields: PropTypes.arrayOf(PropTypes.any),
  initialValues: PropTypes.objectOf(PropTypes.any),
  onFinish: PropTypes.func.isRequired,
};

export default AppointmentForm;
