import * as constants from '@types/userProfileInfo';

const initialState = {
  userInfo: {
    moduleData: {
      'author Portal API': {
        userProfile: {
          fullName: '',
        },
      },
    },
  },
  isLoading: false,
};

const ACTION_HANDLERS = {
  [constants.GET_CONFIGURATION_START]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [constants.GET_CONFIGURATION_SUCCESS]: (state, { payload }) => ({
    ...state,
    userInfo: payload,
    isLoading: false,
  }),
  [constants.GET_CONFIGURATION_ERROR]: (state) => ({
    ...state,
    isLoading: false,
  }),
};

export default (state = initialState, action) => {
  if (action) {
    return ACTION_HANDLERS[action.type] ? ACTION_HANDLERS[action.type](state, action) : state;
  }
  return state;
};
