import React from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NotFoundPage } from 'pages';
import { AppLayout } from 'components/templates';
import { RedirectWithSavingPath } from 'components/organisms';
import { routes, authorizationRoutes } from 'routes/routes';
import { APPOINTMENTS } from 'constants/urls';
import { hasToken } from 'utils/auth';

const PrivateRoute = ({ component: Component, path, ...rest }) => (
  <Route
    {...rest}
    path={path}
    render={(props) => (hasToken() ? <Component {...props} /> : <RedirectWithSavingPath />)}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string,
};

const Root = () => (
  <div className="wrapper dgl">
    <Router>
      <Switch>
        <Redirect exact from="/" to={APPOINTMENTS.path} />
        {authorizationRoutes.map(({ path, component: Component }) => (
          <Route
            exact
            key={path}
            path={path}
            render={(props) => (hasToken() ? <Redirect to={APPOINTMENTS.path} /> : <Component {...props} />)}
          />
        ))}
        <AppLayout>
          <Switch>
            {routes.map(({ path, component }) => (
              <PrivateRoute exact path={path} component={component} key={path} />
            ))}
            <PrivateRoute component={NotFoundPage} />
          </Switch>
        </AppLayout>
      </Switch>
    </Router>
  </div>
);

export default Root;
