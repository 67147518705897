export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENTS_START = 'GET_PATIENTS_START';
export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS';
export const GET_PATIENTS_ERROR = 'GET_PATIENTS_ERROR';

export const CREATE_PATIENT = 'CREATE_PATIENT';
export const CREATE_PATIENT_START = 'CREATE_PATIENT_START';
export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS';
export const CREATE_PATIENT_ERROR = 'CREATE_PATIENT_ERROR';

export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const UPDATE_PATIENT_START = 'UPDATE_PATIENT_START';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_ERROR = 'UPDATE_PATIENT_ERROR';

export const DELETE_PATIENT = 'DELETE_PATIENT';
export const DELETE_PATIENT_START = 'DELETE_PATIENT_START';
export const DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS';
export const DELETE_PATIENT_ERROR = 'DELETE_PATIENT_ERROR';

export const GET_PATIENT = 'GET_PATIENT';
export const GET_PATIENT_START = 'GET_PATIENT_START';
export const GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS';
export const GET_PATIENT_ERROR = 'GET_PATIENT_ERROR';

export const SET_PATIENT = 'SET_PATIENT';
export const SET_PATIENT_FILTER = 'SET_PATIENT_FILTER';
export const RESET_PATIENT_FILTER = 'RESET_PATIENT_FILTER';
