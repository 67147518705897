import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import {
  CalendarOutlined,
  TeamOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { logoutAction } from '@actions/auth';
import { HELP_LINK, APPOINTMENTS, PATIENTS, LETTERS } from 'constants/urls';

import './SideBar.scss';

const menu = [
  {
    path: APPOINTMENTS.path,
    icon: <CalendarOutlined className="sidebar-icon" />,
    name: APPOINTMENTS.title,
  },
  {
    path: LETTERS.path,
    icon: <MailOutlined className="sidebar-icon" />,
    name: LETTERS.title,
  },
  {
    path: PATIENTS.path,
    icon: <TeamOutlined className="sidebar-icon" />,
    name: PATIENTS.title,
  },
];

const SideBarMenu = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutAction());
  };

  const helpAction = () => {
    window.open(HELP_LINK);
  };

  return (
    <Menu className="menu" theme="dark" mode="inline" selectedKeys={[pathname]}>
      {menu.map((item) => (
        <Menu.Item key={item.path} icon={item.icon} className="sidebar-item">
          <NavLink exact to={item.path}>
            {item.name}
          </NavLink>
        </Menu.Item>
      ))}
      <Menu.Item key="help" className="menu__link-signout sidebar-item" onClick={helpAction}>
        <div className="logout-block">
          <QuestionCircleOutlined className="sidebar-icon" />
          <span>Help</span>
        </div>
      </Menu.Item>
      <Menu.Item key="menu-signout" className="menu__link-signout sidebar-item" onClick={logout}>
        <div className="logout-block">
          <LogoutOutlined className="sidebar-icon" />
          <span>Log out</span>
        </div>
      </Menu.Item>
    </Menu>
  );
};

export default SideBarMenu;
