const s4 = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

export const newGuid = () => `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;

export const getClientId = () => {
  const storedClientID = sessionStorage.getItem('clientID');
  if (storedClientID) {
    return storedClientID;
  }
  const clientID = newGuid();
  sessionStorage.setItem('clientID', clientID);
  return clientID;
};
