import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Tabs, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAppointmentAction,
  updateAppointmentAction,
  deleteAppointmentAction,
  getAppointmentAction,
  getAppointmentsAction,
} from '@actions/appointments';
import { createPatientAction, getPatientsAction } from '@actions/patients';
import showNotificationAction from '@actions/notification';

import { submitLoadingSelector as createPatientSelector } from '@selectors/patients';
import {
  submitLoadingSelector,
  deleteLoadingSelector,
  appointmentSelector,
  appointmentListSelector,
  appointmentLoadingSelector,
} from '@selectors/appointments';
import { Loader } from 'components/atoms';
import { checkWarningAppointment } from 'utils/validation';
import PatientForm from '../PatientForm/PatientForm';
import AppointmentForm from '../AppointmentForm/AppointmentForm';
import PatientSearch from '../PatientSearch/PatientSearch';

import './AppointmentModal.scss';

const { TabPane } = Tabs;

const AppointmentModal = ({ visible, closeModal, isEdit, practice, diary, clinician }) => {
  const dispatch = useDispatch();
  const [keyTab, setKeyTab] = useState('appointment');
  const [patientForm] = Form.useForm();
  const [appointmentForm] = Form.useForm();
  const [checkedPatient, setCheckedPatient] = useState();
  const changeCheckbox = (event) => {
    setCheckedPatient(event.target.checked ? event.target.name : undefined);
  };

  const isAppointmentTab = keyTab === 'appointment';

  const appointments = useSelector(appointmentListSelector);
  const currentAppointment = useSelector(appointmentSelector);
  const submitLoading = useSelector(submitLoadingSelector);
  const deleteLoading = useSelector(deleteLoadingSelector);
  const appointmentLoading = useSelector(appointmentLoadingSelector);
  const createPatientLoading = useSelector(createPatientSelector);

  useEffect(() => {
    if (currentAppointment.visitNo) {
      dispatch(getAppointmentAction(currentAppointment.visitNo));
      setCheckedPatient(currentAppointment.patientNo);
    }
  }, [currentAppointment.visitNo]);

  const hideModal = () => {
    setCheckedPatient();
    appointmentForm.resetFields();
    closeModal();
  };

  const refreshAppointments = () => {
    dispatch(getAppointmentsAction());
    hideModal();
  };

  const onFinishCreateAppointment = async (values) => {
    const [startTime, finishTime] = values.time;
    const DateAdmitted = values.date;
    const DateEnd = DateAdmitted.clone();
    DateAdmitted.set('hour', startTime.get('hour'));
    DateAdmitted.set('minute', startTime.get('minute'));
    DateAdmitted.set('second', startTime.get('second'));

    DateEnd.set('hour', finishTime.get('hour'));
    DateEnd.set('minute', finishTime.get('minute'));
    DateEnd.set('second', finishTime.get('second'));

    const description = checkWarningAppointment(
      appointments,
      { ...currentAppointment, start: DateAdmitted.toDate(), end: DateEnd.toDate() },
      isEdit,
    );

    if (description) {
      dispatch(showNotificationAction({ type: 'warning', message: 'Warning', description }));
    } else {
      // fix range date in library
      const firstTime = moment(`2000-01-01T${startTime.format('HH:mm:ss')}`);
      const secondTime = moment(`2000-01-01T${finishTime.format('HH:mm:ss')}`);
      const Duration = secondTime.diff(firstTime) / 1000;
      const appointment = {
        PatientNo: checkedPatient,
        DateAdmitted: DateAdmitted.format('YYYY-MM-DD HH:mm:ss'),
        Clinic: diary.clinic,
        UserProfileId: clinician.id,
        PracticeId: practice.id,
        Duration,
      };
      try {
        if (isEdit) {
          await dispatch(updateAppointmentAction(appointment, currentAppointment.id));
        } else {
          await dispatch(createAppointmentAction(appointment));
        }
      } catch (error) {
        return;
      }

      refreshAppointments();
    }
  };

  const onFinishCreatePatient = async ({
    firstName,
    lastName,
    email,
    dateOfBirth,
    patientNo,
    nhsNumberString,
    phone,
    dglNumber,
  }) => {
    const newPatient = {
      name: {
        firstName,
        lastName,
      },
      dateOfBirth: dateOfBirth.format('YYYY-MM-DD'),
      nhsNumberString,
      email,
      phone,
      practiceId: practice.id,
      dglNumber,
      patientNo,
    };

    await dispatch(createPatientAction(newPatient));
    patientForm.resetFields();
    setKeyTab('appointment');
    dispatch(getPatientsAction());
  };

  const deleteAppointment = async () => {
    await dispatch(deleteAppointmentAction(currentAppointment.id));
    refreshAppointments();
  };

  const getSubmitForm = () => {
    switch (keyTab) {
      case 'appointment':
        return appointmentForm.submit;
      case 'patient':
        return patientForm.submit;
      default:
        return () => {};
    }
  };

  const getSubmitText = () => {
    switch (keyTab) {
      case 'appointment':
        return isEdit ? 'Update' : 'Create';
      case 'patient':
        return 'Create patient';
      default:
        return 'Submit';
    }
  };

  const cancelModal = () => {
    switch (keyTab) {
      case 'appointment':
        hideModal();
        break;
      case 'patient':
        patientForm.resetFields();
        setKeyTab('appointment');
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      title={`${isEdit ? 'Edit' : 'New'} appointment`}
      visible={visible}
      onCancel={hideModal}
      width={650}
      footer={[
        isEdit && isAppointmentTab ? (
          <Popconfirm
            key="delete"
            okText="Yes"
            cancelText="No"
            onConfirm={deleteAppointment}
            title="Do you really want to delete this appointment"
          >
            <Button className="delete-appointment" loading={deleteLoading} danger disabled={appointmentLoading}>
              Delete
            </Button>
          </Popconfirm>
        ) : null,
        <Button key="cancel" onClick={cancelModal}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          htmlType="submit"
          loading={submitLoading || createPatientLoading}
          onClick={getSubmitForm()}
          disabled={isAppointmentTab && !checkedPatient}
        >
          {getSubmitText()}
        </Button>,
      ]}
    >
      <Tabs
        activeKey={keyTab}
        size="small"
        animated={{ inkBar: true, tabPane: false }}
        onChange={setKeyTab}
        className="tab-appointment"
      >
        <TabPane tab="General" key="appointment">
          {appointmentLoading ? (
            <Loader />
          ) : (
            <>
              <AppointmentForm
                form={appointmentForm}
                onFinish={onFinishCreateAppointment}
                fields={[
                  {
                    name: 'practice',
                    value: practice.name,
                  },
                  {
                    name: 'diary',
                    value: diary.name,
                  },
                  {
                    name: 'clinician',
                    value: clinician.fullName,
                  },
                ]}
                initialValues={{
                  date: currentAppointment.start && moment(currentAppointment.start),
                  time: currentAppointment.start && [
                    moment(moment(currentAppointment.start).format('HH:mm'), 'HH:mm'),
                    moment(moment(currentAppointment.end).format('HH:mm'), 'HH:mm'),
                  ],
                }}
              />
              <PatientSearch checkedPatient={checkedPatient} changeCheckbox={changeCheckbox} />
            </>
          )}
        </TabPane>
        <TabPane tab="New Patient" key="patient">
          <PatientForm
            form={patientForm}
            onFinish={onFinishCreatePatient}
            fields={[
              {
                name: 'practice',
                value: practice.name,
              },
            ]}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

AppointmentModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  practice: PropTypes.objectOf(PropTypes.any),
  diary: PropTypes.objectOf(PropTypes.any),
  clinician: PropTypes.objectOf(PropTypes.any),
};

export default AppointmentModal;
