import { connect } from 'react-redux';
import { changePlayerAction, resetPlayerAction } from '@actions/player';
import {
  audioRecordSelector,
  audioLoadingSelector,
  playerDataSelector,
  mp3DataSelector,
  mp3LoadingSelector,
} from '@selectors/player';
import Player from './Player';

const mapStateToProps = (state) => {
  const { playing, seeking, played, loaded, duration, playedSeconds, isBackward, isForward } = playerDataSelector(
    state,
  );
  return {
    playing,
    seeking,
    played,
    loaded,
    duration,
    playedSeconds,
    isBackward,
    isForward,
    record: audioRecordSelector(state),
    isLoading: audioLoadingSelector(state) || mp3LoadingSelector(state),
    mp3Data: mp3DataSelector(state),
  };
};

const mapDispatchToProps = {
  changePlayerAction,
  resetPlayerAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Player);
