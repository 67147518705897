import React from 'react';
import PropTypes from 'prop-types';
import { Logo, PoweredBy } from 'components/atoms';

import './AuthorizationLayout.scss';

const AuthorizationLayout = ({ children }) => {
  return (
    <div className="page authorization-layout">
      <div className="authorization-content">
        <div className="authorization-logo">
          <Logo miniVersion height={60} />
        </div>
        <div className="authorization-box">{children}</div>
      </div>
      <PoweredBy />
    </div>
  );
};

AuthorizationLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthorizationLayout;
