import moment from 'moment';

export const isToday = (date) => date.isSame(moment(), 'day');

export const getRange = (start, end) => {
  const result = [];
  for (let i = start; i < end; i += 1) {
    result.push(i);
  }
  return result;
};

export const RULE_REQUIRED = {
  required: true,
  whitespace: true,
  message: 'Please fill out this field!',
};

export const EMAIL_VALIDATOR = (errorText = 'Email is not valid') => ({
  type: 'string',
  pattern: /^(([^<>()[\]\\.,;:\s@%£«»“”"¬]+(\.[^<>()[\]\\.,;:\s@%£«»“”"¬]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: errorText,
});

export const replacerNHS = (value) => {
  if (value) {
    const result = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
    if (result) {
      const [, first, second, third] = result;
      return `${first} ${second} ${third}`;
    }
  }
  return value;
};

export const checkIsIntersectionTime = (appointments, appointment) => {
  const momentStart = moment(appointment.start);
  const momentEnd = moment(appointment.end);
  const filteredAppointments = appointments.reduce((result, { start, end, id }) => {
    const isAnotherAppointment = !appointment.id || appointment.id !== id;
    if (moment(start).isSame(momentStart, 'day') && isAnotherAppointment) {
      result.push({ start, end });
    }
    return result;
  }, []);

  return filteredAppointments.some(({ start, end }) => {
    return (
      moment(start).isBetween(momentStart, momentEnd) ||
      moment(end).isBetween(momentStart, momentEnd) ||
      momentStart.isBetween(moment(start), moment(end)) ||
      momentStart.isBetween(moment(start), moment(end)) ||
      momentStart.isSame(start) ||
      momentEnd.isSame(end)
    );
  });
};

export const checkWarningAppointment = (appointments, { start, end, id }, isEdit) => {
  let description = '';
  if ((!isEdit || isToday(moment(start))) && start < moment()) {
    description = 'You cannot create an appointment for a past date.';
  } else if (checkIsIntersectionTime(appointments, { start, end, id })) {
    description = 'This time is not available. Please choose another time.';
  }
  return description;
};

export const PASSWORD_VALIDATION = () => ({
  validator: async (rule, value) => {
    if (value) {
      const answer = [];
      // Numerical characters
      if (!/^(?=.*\d).+$/.test(value)) {
        answer.push('* Password should contain at least one numerical character');
      }
      // Special characters
      // if (!(/^(?=.*(_|[^\w])).+$/.test(value))) {
      // 	answer.push('* Password should contain at least one special character');
      // }
      // Lower case characters
      if (!/^(?=.*[a-z]).+$/.test(value)) {
        answer.push('* Password should contain at least one lower case character');
      }
      // Upper case characters
      if (!/^(?=.*[A-Z]).+$/.test(value)) {
        answer.push('* Password should contain at least one upper case character');
      }
      // Password length
      if (value.length < 6 || value.length > 15) {
        answer.push('* Password length should be between 6 and 15 characters');
      }

      if (answer.length) {
        throw new Error(answer.join('\n'));
      }
    }
  },
});
