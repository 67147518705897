import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { loggerAction } = this.props;
    loggerAction({
      content: error.stack + JSON.stringify(errorInfo),
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <h1>Something went wrong.</h1> : children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  loggerAction: PropTypes.func.isRequired,
};

export default ErrorBoundary;
