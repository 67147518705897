import { connect } from 'react-redux';
import { updateLetterAction } from '@actions/letter';
import { changePlayerAction } from '@actions/player';
import showNotificationAction from '@actions/notification';
import { letterSelector, documentSelector } from '@selectors/letter';
import { playerDataSelector } from '@selectors/player';
import Editor from './Editor';

const mapStateToProps = (state) => ({
  document: documentSelector(state),
  letter: letterSelector(state),
  playing: playerDataSelector(state).playing,
});

const mapDispatchToProps = {
  updateLetterAction,
  showNotificationAction,
  changePlayerAction,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Editor);
