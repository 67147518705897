export const GET_LETTERS = 'GET_LETTERS';
export const GET_LETTERS_START = 'GET_LETTERS_START';
export const GET_LETTERS_ERROR = 'GET_LETTERS_ERROR';
export const GET_LETTERS_SUCCESS = 'GET_LETTERS_SUCCESS';

export const GET_AUTHORS = 'GET_AUTHORS';
export const GET_AUTHORS_START = 'GET_AUTHORS_START';
export const GET_AUTHORS_ERROR = 'GET_AUTHORS_ERROR';
export const GET_AUTHORS_SUCCESS = 'GET_AUTHORS_SUCCESS';

export const CHANGE_LETTERS_STATUS = 'CHANGE_LETTERS_STATUS';
export const CHANGE_LETTERS_FILTER = 'CHANGE_LETTERS_FILTER';
export const RESET_LETTERS_FILTER = 'RESET_LETTERS_FILTER';
