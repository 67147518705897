import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Layout } from 'antd';

import { SideBar, UserActivity } from 'components/organisms';
import { refreshTokenAction } from '@actions/auth';

import { isLoadingSelector } from '@selectors/userProfileInfo';
import { getTokenExpiresAt } from 'utils/auth';
import './AppLayout.scss';

const AppLayout = ({ children }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);

  const getTime = () => {
    const refreshTime = getTokenExpiresAt() - Date.now() - 10000;
    return refreshTime > 0 ? refreshTime : 10000;
  };

  useEffect(() => {
    const interval = setInterval(() => dispatch(refreshTokenAction()), getTime());
    return () => clearInterval(interval);
  }, []);

  return (
    <Layout className="gp-layout ant-layout-has-sider">
      <SideBar />
      <Layout>
        <div className="content__layout">{children}</div>
      </Layout>
      {isLoading ? null : <UserActivity />}
    </Layout>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
