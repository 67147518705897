import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import getConfigurationAction from '@actions/userProfileInfo';
import { userFullNameSelector, isLoadingSelector } from '@selectors/userProfileInfo';

import './UserProfileInfo.scss';

const UserProfileInfo = () => {
  const userFullName = useSelector(userFullNameSelector) || '-';
  const isLoading = useSelector(isLoadingSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getConfigurationAction());
  }, []);

  return (
    <div className="user-profile__info">
      <div className="user-profile__info-username">{isLoading ? <LoadingOutlined /> : userFullName}</div>
    </div>
  );
};

export default UserProfileInfo;
