import { createSelector } from 'reselect';

const lettersSelector = (state) => state.letters;

export const lettersStatusSelector = createSelector(lettersSelector, (letters) => letters.lettersStatus);
export const letterListSelector = createSelector(lettersSelector, (letters) => letters.letters);
export const authorListSelector = createSelector(lettersSelector, (letters) => letters.authors);
export const letterListLoadingSelector = createSelector(lettersSelector, (letters) => letters.isLettersLoading);
export const authorListLoadingSelector = createSelector(lettersSelector, (letters) => letters.isAuthorsLoading);
export const letterListTotalSelector = createSelector(lettersSelector, (letters) => letters.lettersTotal);
export const filtersLettersSelector = createSelector(lettersSelector, (letters) => letters.filters);
