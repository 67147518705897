import * as constants from '@types/player';
import * as letterConstants from '@types//letter';

const initialState = {
  record: '',
  isAudioLoading: true,
  player: {
    playing: false,
    seeking: false,
    played: 0,
    loaded: 1,
    duration: 0,
    playedSeconds: 0,
    isBackward: false,
    isForward: false,
  },
  mp3Data: {},
  isMP3Loading: false,
};

const ACTION_HANDLERS = {
  [letterConstants.GET_AUDIO_START]: (state) => ({
    ...state,
    isAudioLoading: true,
  }),
  [letterConstants.GET_AUDIO_SUCCESS]: (state, { payload }) => ({
    ...state,
    record: payload ? `data:audio/ogg;base64,${payload}` : '',
    isAudioLoading: false,
  }),
  [letterConstants.GET_AUDIO_ERROR]: (state) => ({
    ...state,
    isAudioLoading: false,
  }),

  [letterConstants.GET_AUDIO_MP3_START]: (state) => ({
    ...state,
    isAudioLoading: true,
  }),
  [letterConstants.GET_AUDIO_MP3_SUCCESS]: (state, { payload }) => ({
    ...state,
    record: payload ? `data:audio/mp3;base64,${payload}` : '',
    isAudioLoading: false,
  }),
  [letterConstants.GET_AUDIO_MP3_ERROR]: (state) => ({
    ...state,
    isAudioLoading: false,
  }),
  [constants.CHANGE_PLAYER]: (state, { payload }) => ({
    ...state,
    player: {
      ...state.player,
      ...payload,
    },
  }),
  [constants.RESET_PLAYER]: () => ({
    ...initialState,
  }),

  [letterConstants.CHECK_MP3_START]: (state) => ({
    ...state,
    isMP3Loading: true,
  }),
  [letterConstants.CHECK_MP3_SUCCESS]: (state, { payload }) => ({
    ...state,
    mp3Data: payload,
    isMP3Loading: false,
  }),
  [letterConstants.CHECK_MP3_ERROR]: (state) => ({
    ...state,
    isMP3Loading: false,
  }),
};

export default (state = initialState, action) => {
  if (action) {
    return ACTION_HANDLERS[action.type] ? ACTION_HANDLERS[action.type](state, action) : state;
  }
  return state;
};
