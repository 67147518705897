import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CKEditor from 'ckeditor4-react';
import htmlToText from 'html-to-text';
import { createPatch } from 'diff';
import { Spin } from 'antd';
import { detectIE } from 'utils';

import './Editor.scss';

const styles = `
	table td {
		word-break: break-all;
		vertical-align: text-bottom;
	}
	table td p { margin: 0 }
`;
const stringElementStyles = 'margin: 0pt; font-family: Arial; font-size: 12pt;';
const elementStyles = {
  margin: '0pt',
  'font-family': 'Arial',
  'font-size': '12pt',
};

const shiftAltDownCode = 6684712;
const shiftAltArrowLeftCode = 6684709;
const shiftAltArrowRightCode = 6684711;

class Editor extends Component {
  editor = null;

  _isMounted = false;

  timeoutInit = null;

  timeoutLoader = null;

  constructor(props) {
    super(props);
    this.state = {
      isHiddingProccess: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.CKEDITOR.on('dialogDefinition', (ev) => {
      // Take the dialog name and its definition from the event data.
      const dialogName = ev.data.name;
      const dialogDefinition = ev.data.definition;

      // Check if the definition is from the dialog we're
      // interested on (the "Table" dialog).
      if (dialogName === 'table') {
        // Get a reference to the "Table Info" tab.
        const infoTab = dialogDefinition.getContents('info');
        const txtWidth = infoTab.get('txtWidth');
        txtWidth.default = '100%';
      }
    });

    window.CKEDITOR.addCss(styles);
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.timeoutInit) {
      clearTimeout(this.timeoutInit);
    }
    if (this.timeoutLoader) {
      clearTimeout(this.timeoutLoader);
    }
  }

  handleEditorKey = ({ data }) => {
    const { changePlayerAction, playing } = this.props;
    switch (data.keyCode) {
      case shiftAltDownCode:
        changePlayerAction({ playing: !playing });
        break;
      case shiftAltArrowLeftCode:
        changePlayerAction({ isBackward: true });
        break;
      case shiftAltArrowRightCode:
        changePlayerAction({ isForward: true });
        break;
      default:
        break;
    }
  };

  print = () => {
    this.printEditorStyle();
    if (this.editor) {
      this.editor.execCommand('print');
    }
    this.viewEditorStyle();
  };

  copy = () => {
    const { showNotificationAction } = this.props;
    if (!this.editor) {
      return;
    }

    const document = this.editor.document.$;
    const text = document.getElementsByClassName('content')[0];
    if (!text) {
      return;
    }

    const isIE = detectIE();
    const selection = document.getSelection(); // Get the Selection object
    const range = document.createRange(); // Create a new range
    try {
      const copyHandler = (event) => {
        if (event.clipboardData !== undefined) {
          // != IE
          const rawContent = this.editor.getData();
          const textWithoutFormatting = htmlToText.fromString(rawContent, {
            // preserveNewlines: true,
            singleNewLineParagraphs: true,
            unorderedListItemPrefix: ' • ',
            tables: true,
            format: {
              horizontalLine: () => '-'.repeat(80),
            },
          });
          event.clipboardData.setData('text/plain', textWithoutFormatting);
        }
        event.preventDefault(); // default behaviour is to copy any selected text
      };

      if (!isIE) {
        document.addEventListener('copy', copyHandler);
      }

      text.focus();
      range.selectNodeContents(text); // Select the content of the node from line 1
      selection.removeAllRanges(); // Delete any old ranges
      selection.addRange(range); // Add the range to selection

      const isSuccessful = document.execCommand('copy');
      if (isSuccessful) {
        showNotificationAction({
          type: 'success',
          message: 'Success',
          description: 'Copied text to clipboard',
        });
      }

      selection.removeAllRanges();
      if (!isIE) {
        document.removeEventListener('copy', copyHandler);
      }
    } catch (error) {
      showNotificationAction({
        type: 'error',
        message: 'Error',
        description: 'Unable to copy',
      });
    }
  };

  viewEditorStyle = () => {
    const { editor } = this;
    if (editor) {
      const editable = editor.editable();
      if (!editable) {
        return;
      }
      const doc = editable.getDocument();
      const body = doc.getBody();
      if (body) {
        body.setStyles({
          background: '#ececec',
        });
      }
      const content = body.findOne('.content');
      if (content) {
        content.setStyles({
          background: 'white',
          display: 'block',
          margin: '20px auto',
          padding: '30px 40px',
          'box-shadow': '0 0 0.5cm rgba(0,0,0,0.2)',
          width: '680px',
          'min-height': '100vh',
          height: 'auto',
          overflow: 'hidden',
        });
      }
      const area = body.findOne('#editable-area');
      area.setStyles({
        height: 'auto',
        'min-height': '100vh',
      });
    }
  };

  printEditorStyle = () => {
    const { editor } = this;
    if (editor) {
      const editable = editor.editable();
      if (!editable) {
        return;
      }
      const doc = editable.getDocument();

      const body = doc.getBody();
      if (body) {
        body.setStyles({
          background: '#fff',
          padding: '0',
        });
      }
      const content = body.findOne('.content');
      if (content) {
        content.setStyles({
          background: 'white',
          display: 'block',
          margin: '0',
          padding: '0',
          'box-shadow': 'none',
          width: '100%',
          'min-height': 'auto',
          overflow: 'auto',
        });
      }
    }
  };

  savingEditorData = () => {
    const { letter, document, updateLetterAction } = this.props;
    const { documentVersion, editableContent: oldContent, newPatchNumber: patchNumber } = document;
    const { documentId } = letter;
    if (this.editor) {
      const editableArea = this.editor.document && this.editor.document.findOne('#editable-area');
      if (editableArea) {
        const newContent = editableArea.getHtml();
        const patchContent = createPatch('', oldContent, newContent, '', '');
        const requestData = {
          documentVersion,
          patchContent,
          patchNumber,
        };
        updateLetterAction(documentId, requestData);
      }
    }
  };

  // eslint-disable-next-line react/sort-comp
  savingEditorDataDebounced = _.debounce(this.savingEditorData, 500);

  stopAnimation = () => {
    const { changeEditorShow } = this.props;
    changeEditorShow(true);
    this.setState({
      isHiddingProccess: false,
    });
  };

  initEditor = (editor) => () => {
    const {
      letter,
      document: { editableContent },
    } = this.props;

    const isReadOnly = letter.isDeleted || !editableContent;
    if (editor) {
      this.viewEditorStyle();
      const editable = editor.editable();
      const doc = editable.getDocument();
      const body = doc.getBody();
      body.setAttribute('contenteditable', 'false');
      const content = doc.findOne('#editable-area');
      content.setStyle('outline', 'none');
      content.setAttribute('contenteditable', !isReadOnly);

      if (isReadOnly) {
        editor.setReadOnly(isReadOnly);
      }
      this.setState(
        {
          isHiddingProccess: true,
        },
        () => {
          if (this._isMounted) {
            this.timeoutLoader = setTimeout(this.stopAnimation, 400);
          }
        },
      );
    }
    editor.resetUndo();
  };

  handleEditorReady = (evt) => {
    this.editor = evt.editor;
    if (this._isMounted) {
      this.timeoutInit = setTimeout(this.initEditor(evt.editor), 1000);
    }
  };

  handleEditorChange = () => {
    this.savingEditorDataDebounced();
  };

  // // fix paste to word
  handlePluginsLoaded = (evt) => {
    evt.editor.dataProcessor.dataFilter.addRules({
      elements: {
        p(el) {
          el.attributes.style = stringElementStyles;
        },
        li(el) {
          el.attributes.style = stringElementStyles;
        },
        td(el) {
          el.attributes.style = stringElementStyles;
        },
      },
    });
    evt.editor.dataProcessor.htmlFilter.addRules({
      elements: {
        p(el) {
          el.attributes.style = stringElementStyles;
        },
        li(el) {
          el.attributes.style = stringElementStyles;
        },
        td(el) {
          el.attributes.style = stringElementStyles;
        },
      },
    });
  };

  handleRemoveFormat = (event) => {
    const currentElement = event.data.getName();
    if (currentElement === 'p' || currentElement === 'li') {
      event.data.setStyles(elementStyles);
    }
  };

  render() {
    const {
      document: { data: documentText },
      isEditorReadyToShow,
    } = this.props;
    const { isHiddingProccess } = this.state;
    return (
      <div className="ckeditor">
        {!isEditorReadyToShow && (
          <div className={isHiddingProccess ? 'ckeditor__preloader animation' : 'ckeditor__preloader'}>
            <Spin tip="Loading letter" />
          </div>
        )}
        <div className="ckeditor__saving-label">All edits are saved</div>
        <CKEditor
          data={documentText}
          config={{
            language: 'en',
            allowedContent: true,
            tabSpaces: 4,
            title: 'Dictate Swift',
            removePlugins: ['elementspath', 'magicline', 'contextmenu', 'tabletools', 'tableselection'],
            extraPlugins: 'print',
            resize_enabled: false,
            toolbarGroups: [
              { name: 'document', groups: ['mode', 'document', 'doctools'] },
              { name: 'clipboard', groups: ['clipboard', 'undo'] },
              {
                name: 'editing',
                groups: ['find', 'selection', 'spellchecker', 'editing'],
              },
              { name: 'forms', groups: ['forms'] },
              { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
              {
                name: 'paragraph',
                groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
              },
              { name: 'links', groups: ['links'] },
              { name: 'insert', groups: ['insert'] },
              { name: 'styles', groups: ['styles'] },
              { name: 'colors', groups: ['colors'] },
              { name: 'tools', groups: ['tools'] },
              { name: 'others', groups: ['others'] },
              { name: 'about', groups: ['about'] },
            ],
            removeButtons:
              'Cut,Copy,Paste,PasteText,PasteFromWord,Templates,Save,Source,NewPage,Preview,Print,Find,Replace,SelectAll,Scayt,Form,Radio,Checkbox,TextField,Textarea,Select,Button,ImageButton,Image,HiddenField,CreateDiv,Blockquote,Indent,Outdent,BidiLtr,BidiRtl,Language,Anchor,Unlink,Link,Flash,Smiley,SpecialChar,PageBreak,Iframe,About,ShowBlocks,BGColor,TextColor,Maximize,Styles,Format,Font,FontSize',
          }}
          onChange={this.handleEditorChange}
          onKey={this.handleEditorKey}
          onInstanceReady={this.handleEditorReady}
          onPluginsLoaded={this.handlePluginsLoaded}
          onRemoveFormatCleanup={this.handleRemoveFormat}
        />
      </div>
    );
  }
}

Editor.propTypes = {
  letter: PropTypes.objectOf(PropTypes.any),
  document: PropTypes.objectOf(PropTypes.any),
  playing: PropTypes.bool,
  isEditorReadyToShow: PropTypes.bool.isRequired,
  changeEditorShow: PropTypes.func.isRequired,
  showNotificationAction: PropTypes.func.isRequired,
  updateLetterAction: PropTypes.func.isRequired,
  changePlayerAction: PropTypes.func.isRequired,
};

export default Editor;
