import React, { useEffect } from 'react';
import { Modal, Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  createPatientAction,
  updatePatientAction,
  getPatientsAction,
  getPatientAction,
  setPatientAction,
} from '@actions/patients';
import { submitLoadingSelector, patientSelector, isLoadingSelector } from '@selectors/patients';
import { replacerNHS } from 'utils/validation';
import { Loader } from 'components/atoms';
import PatientForm from '../PatientForm/PatientForm';

const PatientModal = ({ visible, closeModal, isEdit, practice }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const submitLoading = useSelector(submitLoadingSelector);
  const patient = useSelector(patientSelector);
  const isLoading = useSelector(isLoadingSelector);

  const hideModal = () => {
    closeModal();
    form.resetFields();
  };

  const onFinish = async ({
    firstName,
    lastName,
    email,
    dateOfBirth,
    patientNo,
    nhsNumberString,
    phone,
    dglNumber,
    sex,
  }) => {
    const newPatient = {
      name: {
        firstName,
        lastName,
      },
      dateOfBirth: dateOfBirth.format('YYYY-MM-DD'),
      nhsNumberString,
      email,
      phone,
      practiceId: practice.id,
      dglNumber,
      patientNo,
      sex,
    };

    try {
      if (isEdit) {
        dispatch(setPatientAction(newPatient));
        await dispatch(updatePatientAction({ ...newPatient }, patient.id));
      } else {
        await dispatch(createPatientAction(newPatient));
      }
    } catch (error) {
      return;
    }

    dispatch(getPatientsAction());
    hideModal();
  };

  const getFields = () => {
    if (isEdit && patient.id) {
      const {
        name: { firstName, lastName },
        nhsNumber: { value },
        sex,
        email,
        phone,
        patientNo,
        dglNumber,
        dateOfBirth,
      } = patient;

      return [
        { name: 'firstName', value: firstName },
        { name: 'lastName', value: lastName },
        { name: 'sex', value: sex },
        { name: 'email', value: email },
        { name: 'dateOfBirth', value: moment(dateOfBirth) },
        { name: 'patientNo', value: patientNo },
        { name: 'nhsNumberString', value: replacerNHS(value) },
        { name: 'phone', value: phone },
        { name: 'dglNumber', value: dglNumber },
        {
          name: 'practice',
          value: practice.name,
        },
      ];
    }
    return [
      {
        name: 'practice',
        value: practice.name,
      },
    ];
  };

  useEffect(() => {
    if (patient.id) {
      dispatch(getPatientAction(patient.id));
    }
  }, [patient.id]);

  return (
    <Modal
      title={isEdit ? 'Edit patient' : 'Create new patient'}
      visible={visible}
      onCancel={hideModal}
      footer={[
        <Button key="cancel" onClick={hideModal}>
          Cancel
        </Button>,
        <Button key="create" type="primary" htmlType="submit" loading={submitLoading} onClick={form.submit}>
          {isEdit ? 'Update' : 'Create patient'}
        </Button>,
      ]}
    >
      {isLoading ? <Loader /> : <PatientForm form={form} onFinish={onFinish} isEdit={isEdit} fields={getFields()} />}
    </Modal>
  );
};

PatientModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  practice: PropTypes.objectOf(PropTypes.any),
};

export default PatientModal;
