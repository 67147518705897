import { STORAGE_OAUTH_KEY } from 'constants/api';
import { LOGIN, SEND_EMAIL, RESET_PASSWORD } from '@types/auth';

const hasToken = () => !!JSON.parse(localStorage.getItem(STORAGE_OAUTH_KEY));

const getToken = () => (hasToken() ? JSON.parse(localStorage.getItem(STORAGE_OAUTH_KEY)).accessToken || '' : '');

const getRefreshToken = () =>
  hasToken() ? JSON.parse(localStorage.getItem(STORAGE_OAUTH_KEY)).refreshToken || '' : '';

const getTokenExpiresAt = () => (hasToken() ? JSON.parse(localStorage.getItem(STORAGE_OAUTH_KEY)).expiresAt || '' : '');

const isAuthType = (type) => type === LOGIN || type === SEND_EMAIL || type === RESET_PASSWORD;

export { hasToken, getToken, getRefreshToken, getTokenExpiresAt, isAuthType };
