import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import enGB from 'antd/lib/locale-provider/en_GB';
import { ErrorBoundary } from 'components/organisms';
import Root from 'routes';
import store from '../redux';

import 'antd/dist/antd.less';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.scss';
import 'styles/theme.scss';

function App() {
  return (
    <ConfigProvider locale={enGB}>
      <Provider store={store}>
        <ErrorBoundary>
          <Root />
        </ErrorBoundary>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
