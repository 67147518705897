import {
  AppointmentsPage,
  AuthorizationPage,
  ChangePasswordPage,
  EmailConfirmPage,
  LetterPage,
  LettersPage,
  PatientsPage,
  ResetPasswordPage,
  ResetPasswordConfirmPage,
} from 'pages';

import {
  APPOINTMENTS,
  CHANGE_PASSWORD,
  EMAIL_CONFIRM,
  LETTER,
  LETTERS,
  LOGIN,
  PATIENTS,
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRM,
} from 'constants/urls';

export const routes = [
  { path: APPOINTMENTS.path, component: AppointmentsPage },
  { path: PATIENTS.path, component: PatientsPage },
  { path: LETTERS.path, component: LettersPage },
  { path: LETTER.path, component: LetterPage },
];

export const authorizationRoutes = [
  { path: LOGIN.path, component: AuthorizationPage },
  { path: CHANGE_PASSWORD.path, component: ChangePasswordPage },
  { path: EMAIL_CONFIRM.path, component: EmailConfirmPage },
  { path: RESET_PASSWORD.path, component: ResetPasswordPage },
  { path: RESET_PASSWORD_CONFIRM.path, component: ResetPasswordConfirmPage },
];
