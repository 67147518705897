import * as constants from '@types/patients';
import defaultPatientsSearch from 'constants/patients';

const initialState = {
  patients: {
    patients: [],
    isLoading: false,
    total: 0,
  },
  currentPatient: {
    patient: {},
    isSubmitLoading: false,
    isLoading: false,
  },
  patientFilters: defaultPatientsSearch,
};

const ACTION_HANDLERS = {
  [constants.GET_PATIENTS_START]: (state) => ({
    ...state,
    patients: {
      ...state.patients,
      isLoading: true,
    },
  }),
  [constants.GET_PATIENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    patients: {
      ...state.patients,
      patients: payload.value,
      isLoading: false,
      total: payload['@odata.count'],
    },
  }),
  [constants.GET_PATIENTS_ERROR]: (state) => ({
    ...state,
    patients: {
      ...state.patients,
      isLoading: false,
    },
  }),
  [constants.SET_PATIENT_FILTER]: (state, { payload }) => ({
    ...state,
    patientFilters: {
      ...state.patientFilters,
      ...payload,
    },
  }),
  [constants.RESET_PATIENT_FILTER]: (state) => ({
    ...state,
    patientFilters: defaultPatientsSearch,
  }),
  [constants.CREATE_PATIENT_START]: (state) => ({
    ...state,
    currentPatient: {
      ...state.currentPatient,
      isSubmitLoading: true,
    },
  }),
  [constants.CREATE_PATIENT_SUCCESS]: (state) => ({
    ...state,
    currentPatient: {
      ...state.currentPatient,
      isSubmitLoading: false,
    },
  }),
  [constants.CREATE_PATIENT_ERROR]: (state) => ({
    ...state,
    currentPatient: {
      ...state.currentPatient,
      isSubmitLoading: false,
    },
  }),
  [constants.UPDATE_PATIENT_START]: (state) => ({
    ...state,
    currentPatient: {
      ...state.currentPatient,
      isSubmitLoading: true,
    },
  }),
  [constants.UPDATE_PATIENT_SUCCESS]: (state) => ({
    ...state,
    currentPatient: {
      ...state.currentPatient,
      isSubmitLoading: false,
    },
  }),
  [constants.UPDATE_PATIENT_ERROR]: (state) => ({
    ...state,
    currentPatient: {
      ...state.currentPatient,
      isSubmitLoading: false,
    },
  }),
  [constants.DELETE_PATIENT_START]: (state) => ({
    ...state,
    currentPatient: {
      ...state.currentPatient,
      isSubmitLoading: true,
    },
  }),
  [constants.DELETE_PATIENT_SUCCESS]: (state) => ({
    ...state,
    currentPatient: {
      ...state.currentPatient,
      isSubmitLoading: false,
    },
  }),
  [constants.DELETE_PATIENT_ERROR]: (state) => ({
    ...state,
    currentPatient: {
      ...state.currentPatient,
      isSubmitLoading: false,
    },
  }),
  [constants.GET_PATIENT_START]: (state) => ({
    ...state,
    currentPatient: {
      ...state.currentPatient,
      isLoading: true,
    },
  }),
  [constants.GET_PATIENT_SUCCESS]: (state, { payload }) => {
    const [patient] = payload.value;
    return {
      ...state,
      currentPatient: {
        ...state.currentPatient,
        isLoading: false,
        patient,
      },
    };
  },
  [constants.GET_PATIENT_ERROR]: (state) => ({
    ...state,
    currentPatient: {
      ...state.currentPatient,
      isLoading: false,
    },
  }),
  [constants.SET_PATIENT]: (state, { payload }) => ({
    ...state,
    currentPatient: {
      ...state.currentPatient,
      patient: {
        ...state.currentPatient.patient,
        nhsNumber: {
          value: payload.nhsNumberString,
        },
        ...payload,
      },
    },
  }),
};

export default (state = initialState, action) => {
  if (action) {
    return ACTION_HANDLERS[action.type] ? ACTION_HANDLERS[action.type](state, action) : state;
  }
  return state;
};
