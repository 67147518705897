import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
// eslint-disable-next-line import/no-cycle
import makeApiCall from 'utils/api';
import { getTokenExpiresAt, isAuthType } from 'utils/auth';
import { logoutAction } from '@actions/auth';
import rootReducer from './reducers';

const sendApiRequest = async (dispatch, { type, requestData }) => {
  const [startType, successType, errorType] = [`${type}_START`, `${type}_SUCCESS`, `${type}_ERROR`];
  try {
    dispatch({ type: startType });
    const result = await makeApiCall(requestData);
    dispatch({ type: successType, payload: result });
    return result;
  } catch (error) {
    // Checking expired token for cors error
    const refreshTime = getTokenExpiresAt() - Date.now() - 10000;
    if (refreshTime < 1 && !isAuthType(type)) {
      dispatch(logoutAction());
    }

    dispatch({ type: errorType, error });
    return Promise.reject(error);
  }
};

const requestMiddlewareThunk = ({ dispatch }) => (next) => (action) => {
  return action.requestData ? sendApiRequest(dispatch, action) : next(action);
};

export default createStore(rootReducer, composeWithDevTools(applyMiddleware(requestMiddlewareThunk, thunkMiddleware)));
