import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table } from 'antd';
import showNotificationAction from '@actions/notification';
import { isForbiddenWorkflow, getLetterStatus } from 'utils/letters';
import { resetLetterAction } from '@actions/letter';

const columns = [
  {
    title: 'Job ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
  },
  {
    title: 'Recorded',
    dataIndex: 'createDate',
    key: 'recordedDate',
    width: '15%',
    render: (date) => (date ? moment(date).format('DD-MMM H:mm') : ''),
  },
  {
    title: 'Patient name',
    dataIndex: 'patientFirstName',
    key: 'patientFirstName',
    width: '20%',
    render: (firstName, { patientLastName: lastName }) => `${firstName ? `${firstName}, ` : ''}${lastName || ''}`,
  },
  {
    title: 'DoB',
    dataIndex: 'patientDateOfBirth',
    key: 'patientDateOfBirth',
    width: '10%',
    render: (date) => (date ? moment(date).format('DD/MM/YY') : ''),
  },
  {
    title: 'NHS No.',
    dataIndex: 'patientNHSNumber',
    key: 'patientNHSNumber',
    width: '13%',
  },
  {
    title: 'Author',
    dataIndex: 'authorName',
    key: 'authorName',
    width: '20%',
  },
  {
    title: 'Status',
    dataIndex: 'stepType',
    key: 'stepType',
    width: '12%',
    render: getLetterStatus,
  },
];

const LettersTable = memo(
  ({ changeTable, currentPage, letters, lettersTotal, isLoading }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const goToLetter = (event, record) => {
      event.preventDefault();
      const { id, stepType, isDeleted } = record;

      if (isForbiddenWorkflow(stepType)) {
        const description = isDeleted
          ? 'This job was deleted and as such is not available to view'
          : "Jobs can only be viewed once they have a status of 'Review'";
        dispatch(
          showNotificationAction({
            type: 'warning',
            message: 'Warning',
            description,
          }),
        );
        return;
      }
      if (!id) return;
      dispatch(resetLetterAction());
      history.push(`/letters/${id}`);
    };

    return (
      <Table
        bordered
        rowKey={(row) => row.id}
        columns={columns}
        dataSource={letters}
        loading={isLoading}
        pagination={{
          total: lettersTotal,
          current: currentPage,
          pageSize: 10,
          showSizeChanger: false,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        onChange={changeTable}
        rowClassName="table-row"
        onRow={(record) => ({
          onClick: (event) => goToLetter(event, record),
        })}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.currentPage === nextProps.currentPage &&
    prevProps.letters === nextProps.letters &&
    prevProps.lettersTotal === nextProps.lettersTotal &&
    prevProps.isLoading === nextProps.isLoading,
);

LettersTable.propTypes = {
  changeTable: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  letters: PropTypes.arrayOf(PropTypes.any).isRequired,
  lettersTotal: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LettersTable;
