import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { AuthorizationLayout } from 'components/templates';
import { loginAction } from '@actions/auth';
import { isSubmitLoadingSelector } from '@selectors/auth';
import { CHANGE_PASSWORD } from 'constants/urls';

import './AuthorizationPage.scss';

const AuthorizationPage = () => {
  const dispatch = useDispatch();
  const isSubmitLoading = useSelector(isSubmitLoadingSelector);
  const onFinish = (values) => {
    dispatch(loginAction(values));
  };

  return (
    <AuthorizationLayout>
      <Form name="authorization" onFinish={onFinish}>
        <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input autoFocus tabIndex="0" size="large" prefix={<UserOutlined />} placeholder="Username" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password tabIndex="0" size="large" prefix={<LockOutlined />} placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button
            tabIndex="0"
            loading={isSubmitLoading}
            size="large"
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
      <div className="forgot-password-link">
        <Link to={CHANGE_PASSWORD.path}>Forgot your password?</Link>
      </div>
    </AuthorizationLayout>
  );
};

export default AuthorizationPage;
