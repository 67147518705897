import { createSelector } from 'reselect';

const userProfileSelector = (state) => state.userProfileInfo.userInfo.moduleData['author Portal API'].userProfile;
const loadingSelector = (state) => state.userProfileInfo.isLoading;
const inactivitySelector = (state) => state.userProfileInfo.userInfo.inactivityTimeoutSeconds;

export const userFullNameSelector = createSelector(userProfileSelector, (userProfile) => userProfile.fullName);
export const userIdSelector = createSelector(userProfileSelector, (userProfile) => userProfile.id);
export const inactivityTimeoutSelector = createSelector(inactivitySelector, (inactivityTimeout) => inactivityTimeout);
export const isLoadingSelector = createSelector(loadingSelector, (loading) => loading);
